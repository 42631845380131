import React from 'react';
import CSSModules from 'react-css-modules';
import ReactDOM from 'react-dom';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './reviewQuiz.module.sass';
import getSkin from './skin.js';
import ReviewQuizHeader from './reviewQuizHeader'
import TakeQuizLoader from '../takeQuiz/takeQuizLoader';
import ReviewQuizNavigator from './reviewQuizNavigator'
import Checkmark from 'svgComponents/checkmark';
import Close from 'svgComponents/close';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ReviewQuiz extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentQuestion: 'question'
		}
	}


	//Disabling this function to avoid unneccessary stringifying entire prop object which is time consuming and need to be done at 2 places

	// shouldComponentUpdate(nextProps, nextState){		
	//     if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {			
	//         return false;
	//     }
	//     return true;
	// }

	componentWillReceiveProps(nextProps) {
		const { moduleItem, quiz } = this.props;

		if (!quiz.get('quizSubmissions').size && nextProps.quiz.get('quizSubmissions').size) {
			let activeAttemptSubmission = null;
			if (nextProps.quiz.get('quizSubmissions').size) {
				activeAttemptSubmission = nextProps.quiz.get('quizSubmissions').find(sub => sub.get('attempt') == this.props.match.params.quizSubmissionAttempt)

				if (activeAttemptSubmission && !activeAttemptSubmission.get('has_seen_results')) {

					let payload = this.props.match.params;

					payload.quizId = moduleItem.get('moduleItem').get('content_id');
					payload.submissionId = activeAttemptSubmission.get('id');

					this.props.markQuizAttemptSeen(payload)
				}
			}
		}
	}

	// componentDidMount() {
	componentWillUnmount() {
		const { moduleItem, quiz } = this.props;
		if (quiz.get('quizSubmissions').size) {
			let activeAttemptSubmission = null;
			activeAttemptSubmission = quiz.get('quizSubmissions').find(sub => sub.get('attempt') == this.props.match.params.quizSubmissionAttempt)

			if (activeAttemptSubmission && !activeAttemptSubmission.get('has_seen_results')) {
				let payload = this.props.match.params;

				payload.quizId = moduleItem.get('moduleItem').get('content_id');
				payload.submissionId = activeAttemptSubmission.get('id');

				this.props.markQuizAttemptSeen(payload)
			}
		}
	}

	setCurrentQuestion = (questionId) => {
		this.setState({
			currentQuestion: `question_${questionId}`
		}, () => this.setScroller())
	}

	exitQuizReview = () => {
		const route = routes.get('BATCH_ITEM_MODULE');
		let params = [
			{ 'BATCH_ID': this.props.match.params.batchId },
			{ '_PHASEITEMID_': this.props.match.params.phaseItemId },
			{ 'COURSE_ID': this.props.match.params.courseId },
			{ '_PHASE_ID_': this.props.match.params.phaseId },
			{ 'MODULE_ID': this.props.match.params.moduleId },
			{ 'ITEM_ID': this.props.match.params.moduleItemId }
		]
		updateRoute({ route, params });
	}

	setScroller = () => {
		let { currentQuestion } = this.state;
		const domNode = ReactDOM.findDOMNode(this[currentQuestion])
		if (domNode) {
			let ele = document.getElementById('scrollable-questions-cnt')
			// ele.scrollTo(0, domNode.offsetTop - 150)
			ele.scrollTop = domNode.offsetTop - 150;
		}
	}

	getOptions = (question, shouldShowCorrectAnswer) => {
		let styles = getSkin(this.props.skinGuide);
		const { moduleItem, quiz } = this.props;
		let optionsElements = null;

		if (question && question.answers) {
			optionsElements = question.answers.map((option) => {

				let isCorrectOption = question.correct_answers.some(ans => option.id == ans.id);
				let isSelectedByUser = question.user_answers.some(ans => option.id == ans.id);

				let optionStyle = "";
				let tickIconComponent = null;
				if (isSelectedByUser) {
					if (isCorrectOption) {
						if (shouldShowCorrectAnswer) {
							optionStyle = "correct-answer-selected-by-user";
							tickIconComponent = <Checkmark fillColor={'#6cbc14'} />;
						} else {
							optionStyle = "answer-selected-by-user";
							tickIconComponent = <Checkmark fillColor={'#2c4bb7'} />;
						}
					} else {
						if (shouldShowCorrectAnswer) {
							optionStyle = "wrong-answer-selected-by-user";
							tickIconComponent = <Close color={'#d0041d'} />;
						} else {
							optionStyle = "answer-selected-by-user";
							tickIconComponent = <Checkmark fillColor={'#2c4bb7'} />;
						}
					}
				} else {
					if (isCorrectOption && shouldShowCorrectAnswer) {
						optionStyle = "correct-answer-not-selected-by-user";
					}
				}

				return (
					<div key={`question_${question.id}_option_${option.id}`} styleName={`option-cnt`}>
						<div
							className={css(styles.optionLabelText)}
							styleName={`option-text ${optionStyle}`}
						>
							{
								question.question_type === "multiple_answers_question" &&
								<div className={css(styles.checkmark)} styleName="checkmark">
									{tickIconComponent}
								</div>
							}
							{
								option.text && option.text !== "" ?
									option.text
									:
									<div dangerouslySetInnerHTML={{ __html: option.html }}></div>
							}
						</div>
					</div>
				)
			})
		}
		return optionsElements;
	}

	getQuestionElements = (questions, shouldShowCorrectAnswer) => {
		let styles = getSkin(this.props.skinGuide);
		let questionElements = null
		// this.setScroller();
		if (questions) {
			questionElements = questions.map((question, index) => {
				return (
					<div key={`question_${question.id}`} styleName="question-data-cnt">
						<div styleName="question-number-row">
							<div className={css(styles.questionNumber)}>
								{this.props.getLabelWithDefaults('question_number_label', `Question ${index + 1}`, { number: index + 1 })}
							</div>
							<div className={css(styles.questionPoints)}>
								{
									this.props.getLabel
										('quiz_question_points_scored_label').replace(
											'POINTS_SCORED',
											question.points
										).replace(
											'MAX_POINTS',
											question.points_possible
										)
								}
							</div>
						</div>
						<div styleName="mcq-cnt" ref={re => { this[`question_${question.id}`] = re }}>
							<div className={css(styles.questionText)} dangerouslySetInnerHTML={{ __html: question.actual_question_text }} styleName="question-text"></div>
							<div styleName="answer-options-cnt">
								{ question.question_type === "essay_question" ?
									<div>{ this.props.getLabel("your_answer_label") + (question?.answer || "") }</div>
								: this.getOptions(question, shouldShowCorrectAnswer)
								}
							</div>
						</div>
					</div>
				)
			})
		}
		return questionElements
	}

	render() {
		console.log("review ====> ", this.props.quiz.toJS())
		const { quiz, moduleItem } = this.props;
		let quizAttemptReviewDataLoading = quiz.getIn(['quizAttemptReviewData', 'quizAttemptReviewDataLoading'])
		let questions = quiz.getIn(['quizAttemptReviewData', 'quiz_submission_questions'])
		let styles = getSkin(this.props.skinGuide);

		let activeAttemptSubmission = null;
		if (quiz.get('quizSubmissions').size) {
			activeAttemptSubmission = quiz.get('quizSubmissions').find(sub => sub.get('attempt') == this.props.match.params.quizSubmissionAttempt)
		}

		let shouldShowCorrectAnswer = moduleItem.getIn(['contentDetails', 'show_correct_answers']);
		if (moduleItem.getIn(['contentDetails', 'show_correct_answers_last_attempt'])) {
			if (activeAttemptSubmission && moduleItem.getIn(['contentDetails', 'allowed_attempts']) === activeAttemptSubmission.get('attempt')) {
				shouldShowCorrectAnswer = true;
			} else {
				shouldShowCorrectAnswer = false;
			}
		}
		if (/survey/.test(moduleItem.getIn(['contentDetails', 'quiz_type'])))
			shouldShowCorrectAnswer = false

		return (
			<div className={css(styles.quizQuestionsOuterCnt)} styleName="quiz-question-outer-cnt">
				{
					quizAttemptReviewDataLoading || !moduleItem.get('isModuleItemDataAvailable') ?
						<TakeQuizLoader />
						:
						[
							<ReviewQuizHeader key="quiz-header" {...this.props} activeAttemptSubmission={activeAttemptSubmission} exitAction={this.exitQuizReview} />,
							<div key="quiz-questions" className={css(styles.quizDataCnt)} styleName="quiz-questions-data">
								<div className={css(styles.questionListCnt)} styleName="question-list-cnt" id="scrollable-questions-cnt">
									{this.getQuestionElements(questions, shouldShowCorrectAnswer)}
								</div>
								<div className={css(styles.questionNavigatorCnt)} styleName="question-navigator-cnt">
									<ReviewQuizNavigator
										questions={questions}
										setCurrentQuestion={this.setCurrentQuestion}
										showCorrectAnswers={shouldShowCorrectAnswer}
									/>
								</div>
							</div>
						]
				}
			</div>
		)
	}
}

export default ReviewQuiz;