import React, { Component } from 'react';
import styles from './scormsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScormsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="scorm-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 15">
					<path fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" d="M11.62 9.59l1.794-1.794a.813.813 0 0 0 0-1.149l-1.143-1.14A2.031 2.031 0 1 1 9.61 2.845L8.47 1.702a.812.812 0 0 0-1.15 0L5.527 3.496a2.031 2.031 0 1 0-2.031 2.03L1.702 7.32a.812.812 0 0 0 0 1.15l1.144 1.14a2.031 2.031 0 1 1 2.66 2.66l1.141 1.142a.813.813 0 0 0 1.149 0l1.793-1.791a2.031 2.031 0 1 0 2.032-2.032z" />
				</svg>
			</div>
		);
	}
}

export default ScormsIcon;
