import actionConsts from 'constants/actions';

const setItemsList = payload => ({
    type: actionConsts.get('SET_ITEMS_LIST'),
    payload
});

const setUserItemsList = payload => ({
    type: actionConsts.get('SET_USER_ITEMS_LIST'),
    payload
});

const updateItemDetailedData = payload => ({
    type: actionConsts.get('UPDATE_ITEM_DETAILED_DATA'),
    payload
});

const updateItemMetricsList = metricsList => ({
    type: actionConsts.get('UPDATE_ITEMS_METRICS_LIST'),
    metricsList
});

const setProductLaunchDetails = productLaunchDetails => ({
    type: actionConsts.get('SET_PRODUCT_LAUNCH_DETAILS'),
    productLaunchDetails
});

const setProductLaunchDetailsFetching = flag => ({
    type: actionConsts.get('SET_PRODUCT_LAUNCH_FETCHING'),
    flag
});

const resetProductLaunchDetails = productLaunchDetails => ({
    type: actionConsts.get('RESET_PRODUCT_LAUNCH_DETAILS'),
    productLaunchDetails
});

const toggleProductLaunchWindow = flag => ({
    type:actionConsts.get('OPEN_PRODUCT_LAUNCH_WINDOW'),
    flag
});

const setSelectedItemId = id => ({
    type:actionConsts.get('SET_ACTIVE_ITEM_ID'),
    id
});

const updateItemIdFromRoute = (itemIdFromRoute) => ({
    type: actionConsts.get('UPDATE_ITEM_FROM_ROUTE'),
    itemIdFromRoute
});

const submitFeedback = (flag) =>({
    type: actionConsts.get('SUBMIT_FEEDBACK'),
    flag
});

const setExitPopup = (flag) => ({
	type: actionConsts.get('SET_EXIT_POPUP'),
	flag
})

const setAssessmentShowDetails = (flag) => ({
	type: actionConsts.get('SET_ASSESSMENT_SHOW_DETAILS'),
	flag
})

const updateItemProgressInList = (progressDetails) => ({
    type: actionConsts.get('UPDATE_ITEM_PROGRESS_IN_LIST'),
    progressDetails
})

export {
    setItemsList,
    setUserItemsList,
    setProductLaunchDetails,
    toggleProductLaunchWindow,
    updateItemDetailedData,
    updateItemMetricsList,
    setSelectedItemId,
    updateItemIdFromRoute,
    submitFeedback,
    setExitPopup,
    setAssessmentShowDetails,
    resetProductLaunchDetails,
    setProductLaunchDetailsFetching,
    updateItemProgressInList
};
