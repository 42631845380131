import React, { Component } from 'react';
import styles from './journeyCatalogue.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import JourneyCard from 'commonComponents/journeyCard';
import DefaultLoader from 'commonComponents/loaders/defaultLoader/DefaultLoader';
import JourneyIntroductionPopupContainer from 'containers/JourneyIntroductionPopupContainer';
import Option from 'commonComponents/customizedSelectOption';
import Select from "react-select";
import debounce from 'lodash/debounce';
import NoJourneyErrorComponent from 'commonComponents/noJourneyErrorComponent';
import { isMobile } from 'react-device-detect';
import { checkIfValueIsTrue } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class JourneyCatalogue extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCategories: [],
			selectedFormats: [],
			searchText: "",
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null
		}
		this.autoSearchJourneys = debounce(this.fetchSearchedJourneys, 800);
	}

	setJourneyIntroductionPopup = (selectedJourneyInstanceId) => {
		this.setState({
			showJourneyIntroductionPopup: true,
			selectedJourneyInstanceId: selectedJourneyInstanceId
		});
	}

	unsetJourneyIntroductionPopup = () => {
		this.setState({
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null
		});
	}

	addOrRemoveFilterValue = (filter, value) => {
		let updatedFilter = value.map(obj => obj.value)
		this.setState({
			[filter]: updatedFilter
		});
	}

	handleSearch = (value) => {
		this.setState({
			searchText: value
		}, () => {
			this.autoSearchJourneys();
		});
	}

	fetchSearchedJourneys = () => {
		this.props.fetchJourneyCatalogueData(this.props.fromPublicCatalog, this.state.searchText)
	}

	getFilterComponent = (allValues, myStyles, keyName, valueName, filterName, stateFilterName, prefix) => {
		let filterOptions = [];
		const selfPacedOption = allValues.filter(value => value.get("key") === "SELF_PACED");
		const restOptions = allValues.filter(value => value.get("key") !== "SELF_PACED");
		selfPacedOption.forEach(value => {
			filterOptions.push(
				{
					value: value.get(keyName),
					label: this.props.getLabel(`${prefix}_${value.get(keyName)}_label`)
				}
			)
		});
		filterOptions.push(
			{
				value: restOptions.reduce((acc, value) => acc + value.get(keyName) + ",", ""),
				label: restOptions.reduce((acc, value) => {
					return acc + this.props.getLabel(`${prefix}_${value.get(keyName)}_label`) + "/";
				}, "").slice(0, -1)
			}
		);
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				margin: '2px 0',
				backgroundColor: 'white',
				padding: '5px 10px'
			})
		}

		return (
			<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
				<div className={css(myStyles.filterName)}>{filterName}:</div>
				<div style={ isMobile ? { maxWidth: '200px', minWidth: '200px', marginLeft: '10px' } : { maxWidth: '250px', minWidth: '250px', marginLeft: '15px' }}>
					<Select
						closeMenuOnSelect={false}
						isMulti
						components={{ Option }}
						options={filterOptions}
						hideSelectedOptions={false}
						backspaceRemovesValue={false}
						onChange={e => this.addOrRemoveFilterValue(stateFilterName, e)}
						placeholder={this.props.getLabel('filters_all_selected_label')}
						styles={customStyles}
					/>
				</div>
			</div>
		)
	}

	getFilterAndSearchComponent = (myStyles) => {
		const {
			journeyFormats,
			journeyCategories,
			getLabel
		} = this.props;

		const {
			searchText
		} = this.state;

		let journeyFormatFilterComponent = this.getFilterComponent(journeyFormats, myStyles, "key", "value", getLabel('journey_formats_label'), "selectedFormats", "format");

		return (
			<div styleName="journey-filters-and-search-cnt">
				<div styleName="journey-search-cnt">
					<input
						value={searchText}
						placeholder={getLabel('search_journeys_placeholder_label')}
						onChange={e => this.handleSearch(e.target.value)}
					/>
				</div>
				<div styleName="journey-filters-cnt">
					{journeyFormatFilterComponent}
				</div>
			</div>
		)
	}

	getAvailableJourneyCards = (myStyles) => {
		const {
			catalogueJourneys,
			journeyCategories,
			userOrganization,
			getLabel
		} = this.props;


		if (!catalogueJourneys.get('availableJourneys') || catalogueJourneys.get('isAvailableJourneysFetching')) {
			return (
				<div style={{ height: '600px', width: '1280px', margin: 'auto' }}>
					<DefaultLoader />
				</div>
			)
		}

		const {
			selectedFormats
		} = this.state;

		if (catalogueJourneys.get('availableJourneys').size === 0) {
			return (
				<div styleName="category-wise-journeys-cnt">
					<NoJourneyErrorComponent />
				</div>
			)
		}

		let filteredJourneys = catalogueJourneys.get("availableJourneys");

    if(selectedFormats.length > 0) {
			filteredJourneys = filteredJourneys.filter(batch => {
				const format = batch.getIn(["journey", "format"]);

				if(format) {
					for(let i = 0; i < selectedFormats.length; i++) {
						if(selectedFormats[i].includes(format.get("key"))) {
							return true;
						}
					}
				}

				return false;
			})
		}

		const categoryOrder = Object.freeze({
			COURSES: 0,
			BUNDLES: 1,
			JOURNEYS: 2
		});

		const categories = [...journeyCategories];
		categories.sort((c1, c2) => categoryOrder[c1.get("key")] - categoryOrder[c2.get("key")]);

		const categoriesComponent = [];

    categories.forEach(category => {
      let currentCategoryJourneys = filteredJourneys.filter(journey => journey.getIn(["journey", "categories"]).size > 0 && journey.getIn(["journey", "categories"]).get(0).get("key") === category.get("key"));

			if(category.get("key") === "COURSES") {
				const uncategoriesJourneys = filteredJourneys.filter(journey => journey.getIn(["journey", "categories"]).size === 0);

				currentCategoryJourneys = currentCategoryJourneys.push(...uncategoriesJourneys);
			}

			currentCategoryJourneys = currentCategoryJourneys.sort((j1, j2) => j1.get("name").localeCompare(j2.get("name")));

			if(category.get("key") === "COURSES") {
				const simOrder = {
					microSim: 1,
					macroSim: 2,
					nanoSim: 3,
					nonSim: 4
				};

				const getSimOrder = (colorCode) => {
					if(!colorCode || colorCode === "") {
						return simOrder.nonSim
					}

					return simOrder[colorCode] || simOrder.nonSim;
				}

				currentCategoryJourneys = currentCategoryJourneys.sort((j1, j2) => {
					const j1SimColorCode = getSimOrder(j1.getIn(["journey", "settings", "simColourCode"]));
					const j2SimColorCode = getSimOrder(j2.getIn(["journey", "settings", "simColourCode"]));

					return j1SimColorCode - j2SimColorCode;
				});
			}

			if(category.get("key") === "BUNDLES") {

				const bundleType = Object.freeze({
					leadership: 0,
					managers: 1,
					ic: 2
				});

				currentCategoryJourneys = currentCategoryJourneys.sort((j1, j2) => bundleType[j1.getIn(["journey", "settings", "bundleType"])] - bundleType[j2.getIn(["journey", "settings", "bundleType"])]);
			}

			let currentCategoryCards = [];

			if(currentCategoryJourneys.size > 0) {
				currentCategoryCards = currentCategoryJourneys.map(journey => {
					return (
						<JourneyCard
							key={`completed_active_batch_${journey.get('id')}`}
							batchDetails={journey}
							isAvailable={true}
							setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
							userOrganization={userOrganization}
						/>
					);
				});

				categoriesComponent.push(
					<div key={`category_journeys_${category.get('key')}`} styleName="category-wise-journeys-cnt">
						<div className={css(myStyles.categoryName)}>
							{getLabel(`category_${category.get('key')}_label`)}
						</div>
						<div styleName="journey-cards-cnt">
							{currentCategoryCards}
						</div>
					</div>
				);
			}	
    });

		if (categoriesComponent.length === 0) {
			return (
				<div styleName="category-wise-journeys-cnt">
					<NoJourneyErrorComponent />
				</div>
			)
		}

		return categoriesComponent;
	}

	render() {
		const {
			showJourneyIntroductionPopup,
			selectedJourneyInstanceId,
			searchText
		} = this.state;

		const myStyles = getSkin(this.props.skinGuide);

		let exploreCoursesMessage = "";

		if(checkIfValueIsTrue(this.props.userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]))) {
			exploreCoursesMessage = this.props.getLabel("interest-enabled-msg-label");
		} else if(checkIfValueIsTrue(this.props.userOrganization.getIn(["userOrganization", "platformSettings", "autoEnrollToSelfPacedJourney"]))) {
			exploreCoursesMessage = this.props.getLabel("autoenroll-enabled-msg-label");
		}

		return (
			<div styleName="journey-catalogue-cnt">
			<div styleName="explore-courses-msg">{exploreCoursesMessage}</div>
				{this.getFilterAndSearchComponent(myStyles)}
				{this.getAvailableJourneyCards(myStyles)}
				{
					showJourneyIntroductionPopup &&
					<JourneyIntroductionPopupContainer
						journeyInstanceId={selectedJourneyInstanceId}
						closePopup={this.unsetJourneyIntroductionPopup}
						alwaysSignUp={this.props.alwaysSignUp}
						searchText={searchText}
					/>
				}
			</div>
		);
	}
}

export default JourneyCatalogue;