/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: DashboardContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Dashboard container is responsible for displaying Dashboard pages and
 * displayed on dashboard route of the application
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import DesktopRouter from 'router/DesktopRouter';
import DesktopHeader from 'commonComponents/desktopHeader';
import Overlay from 'commonComponents/overlay';
import ConfirmationPopup from 'commonComponents/confirmationPopup';

import { fetchBatchesList, resetBatchProgressForUser } from 'actions/batches/apiActions';
import { fetchAnalyticsButtonData } from 'actions/analytics/apiActions';
import { fetchAppBaseData } from 'actions/init/apiActions';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import setUiState from 'actions/ui/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { setLogoutClicked } from 'actions/users/actionCreators';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import routes from 'utils/webAppRoutes/routeConstants';
import ToastOverlay from 'commonComponents/ToastOverlay';
import VideoOverlay from 'commonComponents/videoOverlay';
import UserError from 'commonComponents/userError';
import { toast } from 'react-toastify';
import ToastComponent from 'commonComponents/toastComponent';
import { setUserOnlineStatus } from 'actions/onlineCheck/actionCreators';
import { updateToastDisplayedStatus, removeToast } from 'actions/toasts/actionCreators';
import ErrorContainer from 'containers/ErrorContainer';
import GenericErrorBoundary from 'components/errorBoundaries';
import { signOutFromALPlus } from 'utils/utilFunctions';

import AppLoader from 'commonComponents/appLoader';
import { shouldShowBatchAnalytics } from 'utils/utilFunctions';
import { getActiveBatchDetails } from 'utils/batchUtils';
import applyLabel from 'utils/LabelProvider';
import { isMSTeams } from 'utils/msTeamsUI';
import { getAppStateFromUrl } from 'utils/urls';

import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';

import JourneyCatalogContainer from 'containers/JourneyCatalogContainer';
import Immutable from 'immutable';
import SystemCheckContainer from 'containers/SystemCheckContainer';
import SimpleDialog from 'commonComponents/simpleDialog';
import IE11Warning from 'components/systemCheck/ie11Warning';
import UserOrganizationsList from 'components/userProfile/UserOrganizationsList';
import { isIPadOrIPhone } from 'utils/browser';
import ReturnFromLinkedin from 'commonComponents/returnFromLinkedin';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import NavigationListener from 'components/navigationListener/NavigationListener';

@applyLabel
class DesktopDashboardContainer extends React.Component {

    constructor(props) {
        super(props);

        if (!window.location.pathname.includes('/catalog/') && !props.user.get('isUserDataAvailable')) {
            props.fetchAppBaseData()
        }
    }

    componentDidMount() {
        setInterval(() => {
            const isUserOnline = window.navigator.onLine;
            if (isUserOnline === false) {
                const { setUiState } = this.props;
                setUiState({ showInternetConnectivityBar: true });
            }
        }, 3000);


        if (!window.location.pathname.includes('/catalog/') && this.props.location.pathname === '/') {
            this.props.fetchAppBaseData();
        }
    }

    componentWillMount() {
        this.checkAndSetBatchAnalyticsFlag(this.props.batches);
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (this.props.location.pathname !== '/' && nextProps.location.pathname === '/') {
            this.props.fetchAppBaseData();
        }

        const currentBatchDetails = getActiveBatchDetails(this.props.batches);
        const nextBatchDetails = getActiveBatchDetails(nextProps.batches);

        if (
            (this.props.location.pathname !== nextProps.location.pathname)
            || (this.props.user.get('isUserDataAvailable') !== nextProps.user.get('isUserDataAvailable'))
            || (this.props.batches.get('isActiveBatchFetched') !== nextProps.batches.get('isActiveBatchFetched'))
            || (this.props.items.get('isProductLaunchWindowOpen') !== nextProps.items.get('isProductLaunchWindowOpen'))
            || (currentBatchDetails.get('isDetailedDataAvailable') !== nextBatchDetails.get('isDetailedDataAvailable'))
        ) {
            if (shouldShowBatchAnalytics(nextProps.batches)) {
                this.fetchActivityAndPerformanceButtonData(nextProps);
            }
        }
        this.checkAndSetBatchAnalyticsFlag(nextProps.batches);
        this.checkAndDisplayToast(nextProps.toasts.get('toastsList'));
    }

    checkAndSetBatchAnalyticsFlag = (batches) => {
        if (shouldShowBatchAnalytics(batches)) {
            this.props.setUserState({
                batchAnalyticsFlag: true
            });
        }
    }

    fetchActivityAndPerformanceButtonData = (nextProps) => {
        if (
            nextProps.user.get('isUserDataAvailable')
            && nextProps.batches.get('isActiveBatchFetched')
        ) {
            this.props.fetchAnalyticsButtonData(
                nextProps.batches.get('activeBatchId'),
                nextProps.user.get('userID') ? nextProps.user.get('userID') : nextProps.user.get('userId')
            )
        }
    }

    checkAndDisplayToast = (nextToastsList) => {
        nextToastsList.map((toastObj) => {
            if (!toastObj.get('isDisplayed')) {
                this.props.updateToastDisplayedStatus(toastObj.get('id'), true);
                toast(
                    <ToastComponent
                        type={toastObj.get('type')}
                        heading={toastObj.get('heading')}
                        message={toastObj.get('message')}
                        id={toastObj.get('id')}
                    />,
                    {
                        autoClose: toastObj.get('autoCloseTime'),
                        onClose: ({ id }) => (this.props.removeToast(id))
                    }
                );
            }
        });
    }

    redirectToHome = () => {
        let appState = window.localStorage.getItem('appState');

        if (this.props.userOrganization.get('userOrganizationDataLoading')) {
            return <AppLoader />
        }

        if (this.props.userOrganization.get('userOrganization').size === 0) {
            if (this.props.userOrganization.get('userOrganizationError').size > 0) {
                return this.getAccessUnavailablePage('ORGANIZATION_NOT_FOUND', { height: 'calc(100vh - 59px)' });
            }
            return null
        }

        if (appState) {
            updateRoute({ route: routes.get('JOURNEY_CATALOG') });
        } else {
            updateRoute({ route: routes.get('USER_HOME') });
        }
        return null;
    }


    renderOverlay = () => {
        return (
            <ReactCSSTransitionGroup
                transitionName="overlayOpacity"
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={1000}
                transitionEnter={true}
                transitionLeave={true}
            >
                {
                    this.props.uiState.get('showOverlay')
                        ? <Overlay {...this.props} setUiState={this.props.setUiState} />
                        : <div key="closeOverlay"></div>
                }
            </ReactCSSTransitionGroup>
        );
    }

    renderVideoOverlay = () => {
        if (this.props.uiState.get('showVideoOverlay')) {
            return (<VideoOverlay {...this.props} />);
        }
        return null;
    }

    renderSystemCheckOverlay = () => {
        if (this.props.uiState.get('showSystemCheckDialog')) {
            return <SimpleDialog onCloseDialog={
                () => {
                    this.props.setUiState({
                        showSystemCheckDialog: false
                    })
                }
            }>
                <SystemCheckContainer loadedInsideDialog={true} context="home" />
            </SimpleDialog>
                ;
        }
        return null;
    }

    renderOrganizationsListOverlay = () => {
        if (this.props.uiState.get('showOrganizationsDialog')) {
            return <SimpleDialog onCloseDialog={
                () => {
                    this.props.setUiState({
                        showOrganizationsDialog: false
                    })
                }}

                contentContainerStyle={{
                    width: '500px',
                    minWidth: '400px',
                    padding: '20px',
                    marginTop: '20vh',
                    height: '250px',
                    maxHeight: '400px'
                }}
            >
                <UserOrganizationsList userOrganization={this.props.userOrganization} />
            </SimpleDialog>
                ;
        }
        return null;
    }

    renderIE11Warning = () => {
        if (this.props.userState.get('ie11WarningAcknowledged') === "false") {
            return <IE11Warning setUserState={this.props.setUserState} />;
        }
        return null;
    }

    renderErrorComponent = () => {
        return <ErrorContainer {...this.props} />
    }

    onScrollContainer = (e) => {
        if(isMobile || isTablet || isIPad13) return ;
        const divRefs = this.props.userState.get('invitationDivRefs');
        divRefs.map((singleRef, key) => {
            if (singleRef.current !== null && singleRef.current !== undefined) {
                if (singleRef.current.getBoundingClientRect().y < 220) {
                    this.props.setUserState({
                        invitationDivName: key
                    });
                }
            }
        });

        if (isMSTeams() || isLaunchedFromExternalIntegration()) {
            this.props.setUserState({
                showStickyHeader: false
            });
        } else {
            if (
                this.props.userState.get('stickyRef').current !== undefined
                && this.props.userState.get('stickyRef').current !== null
                && this.props.userState.get('stickyRef').current.getBoundingClientRect().y + this.props.userState.get('stickyRef').current.clientHeight - 85 <= 0
            ) {

                this.props.setUserState({
                    showStickyHeader: true
                });
            } else {
                this.props.setUserState({
                    showStickyHeader: false
                });
            }
            if (e.target.scrollTop >= 59) {
                this.props.setUserState({
                    makeLeftBarFixed: true
                });
            } else {
                this.props.setUserState({
                    makeLeftBarFixed: false
                });
            }
        }
    }

    renderRoute = () => {
        return <DesktopRouter {...this.props}  {...this.state} />;
    }

    logout = () => {
        signOutFromALPlus();
    }

    renderLogoutConfirmation = () => {
        const isLogoutClicked = this.props.uiState.get('isLogoutClicked');
        if (isLogoutClicked) {
            return (
                <ConfirmationPopup
                    isLogoutClicked={isLogoutClicked}
                    confirmationText={
                        this.props.getLabelWithDefaults(
                            'logout_confirmation_message',
                            'Are you sure you want to sign out?'
                        )
                    }
                    yesText={
                        this.props.getLabelWithDefaults(
                            'logout_label',
                            'Sign Out'
                        ).toUpperCase()
                    }
                    cancelText={
                        this.props.getLabelWithDefaults(
                            'cancel_label',
                            'CANCEL'
                        ).toUpperCase()
                    }
                    yesClickHandler={this.logout}
                    cancelClickHandler={this.props.setLogoutClicked}
                />
            );
        }
        else {
            return null;
        }
    }

    isDevPhaseScreen = () => {
        return (this.props.location.pathname.includes("modules") && this.props.location.pathname.includes("items")) || this.props.location.pathname.includes("course_feedback");
    }

    isMSTeamsScreen = () => {
        return this.props.location.pathname.includes("msteams");
    }

    isPublicJourneyCatalogueScreen = () => {
        return this.props.location.pathname.includes("/catalog/");
    }

    getHeaderComponent = () => {
        if (this.isMSTeamsScreen()) return <span></span>;
        if (this.isPublicJourneyCatalogueScreen()) return null;

        const isHidden = this.props.location.pathname.includes("takeQuiz") || this.isDevPhaseScreen() || (this.props.location.pathname.includes("batch") && this.props.userState.get('batchToRunSystemCheckFor') !== null);

        return <DesktopHeader
            {...this.props}
            isHidden={isHidden}
        />;
    }

    redirectToAppStateHash = (frontEndRouteHash) => {
        window.location.href = `${window.location.origin}/${frontEndRouteHash}`;
        return null;
    }

    getAccessUnavailablePage = (errorCode, componentStyles = {}) => {
        return <UserError
            logout={this.logout}
            userError={Immutable.fromJS({ error_code: errorCode })}
            componentStyles={componentStyles}
            userOrganization={this.props.userOrganization}
        />;
    }

    render() {
        if (this.props.userState.get('userError').get('error_code') != null) {
            return this.renderErrorComponent();
        }

        const appStateFromUrl = getAppStateFromUrl();
        if (appStateFromUrl && appStateFromUrl.frontEndRouteHash) {
            return this.redirectToAppStateHash(appStateFromUrl.frontEndRouteHash);
        }

        if (window.location.pathname.includes('/catalog/')) {
            let orgCode = window.location.pathname.split('/')[2];
            return <JourneyCatalogContainer orgCode={orgCode} />
        }

        if (!this.props.user.get('isUserDataAvailable') || this.props.userOrganization.get('userOrganization').size === 0) {
            return <AppLoader />;
        }

        if (this.props.userState.get('systemCheckDone') === "false") {
            return <SystemCheckContainer context="home" />;
        }

        if (this.props.location.pathname === '/') {
            return this.redirectToHome();
        }

        if (this.props.location.pathname === '/return-from-linkedin') {
            return <ReturnFromLinkedin/>;
        }

        let componentToRender = this.renderRoute();

        if (this.props.userOrganization.getIn(['userOrganization', 'isActive']) === false) {
            componentToRender = this.getAccessUnavailablePage('USER_SUSPENDED_FROM_ORGANIZATION', { height: 'calc(100vh - 59px)' });
        } else if (this.props.userOrganization.getIn(['userOrganization', 'isDealUpcoming']) === true) {
            componentToRender = this.getAccessUnavailablePage('DEAL_UPCOMING', { height: 'calc(100vh - 59px)' });
        } else if (this.props.userOrganization.getIn(['userOrganization', 'isDealExpired']) === true) {
            componentToRender = this.getAccessUnavailablePage('DEAL_EXPIRED', { height: 'calc(100vh - 59px)' });
        }

        return (
            <div style={isIPadOrIPhone() ? {} : { width: "100%", height: "100vh", overflowY: "auto" }}
                ref={this.props.userState.get('completeContainerRef')}
                onScroll={(e) => {

                    //Adding this condition to avoid re-render for module item (specially while taking/reviewing quiz) after there is a scroll event

                    if (!this.isDevPhaseScreen()) {
                        this.onScrollContainer(e);
                    }
                }}
            >

                <GenericErrorBoundary>
                    <NavigationListener/>
                    {this.renderIE11Warning()}
                    {this.getHeaderComponent()}
                    {componentToRender}
                    <ToastOverlay />
                    {this.renderOverlay()}
                    {this.renderVideoOverlay()}
                    {this.renderSystemCheckOverlay()}
                    {this.renderOrganizationsListOverlay()}
                    {this.renderLogoutConfirmation()}
                </GenericErrorBoundary>
            </div>
        );
    }
}

DesktopDashboardContainer.propTypes = {};

DesktopDashboardContainer.defaultProps = {};

const mapStateToProps = state => ({
    user: state.get('user'),
    batches: state.get('batches'),
    uiState: state.get('uiState'),
    phases: state.get('phases'),
    items: state.get('items'),
    loaders: state.get('loaders'),
    userState: state.get('userState'),
    netpack: state.get('netpack'),
    toasts: state.get('toasts'),
    errors: state.get('errors'),
    course: state.get('course'),
    labels: state.get('labels'),
    userOrganization: state.get('userOrganization'),
    coursesModulesList: state.get('coursesModulesList'),
    modulesItemsList: state.get('modulesItemsList')
});

const mapDispatchToProps = dispatch => ({
    fetchAppBaseData: () => {
        dispatch(fetchAppBaseData());
    },
    fetchBatchesList: () => {
        dispatch(fetchBatchesList());
    },
    setUiState: (payload) => {
        dispatch(setUiState(payload));
    },
    setUserState: (payload) => {
        dispatch(setUserState(payload));
    },
    setUserOnlineStatus: (flag) => {
        dispatch(setUserOnlineStatus(flag))
    },
    updateToastDisplayedStatus: (id, status) => {
        dispatch(updateToastDisplayedStatus(id, status));
    },
    removeToast: (id) => {
        dispatch(removeToast(id));
    },
    setLogoutClicked: (flag) => {
        dispatch(setLogoutClicked(flag))
    },
    fetchAnalyticsButtonData: (batchId, userId) => {
        dispatch(fetchAnalyticsButtonData(batchId, userId));
    },
    resetBatchProgressForUser: (batchId, userId) => {
        dispatch(resetBatchProgressForUser(batchId, userId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopDashboardContainer);
