import store from 'store/store';
import NetPack from 'utils/NetPack';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import { internetConnectivityHandler } from 'actions/netpackActions/internetConnectivity';
import {
    parseErrorHandler,
    backendErrorHandler,
    networkErrorHandler
} from 'actions/netpackActions/error';
import environment from 'utils/environment';
import { isIE } from 'react-device-detect';
import urls from 'constants/urls/urls';
import redirect from 'utils/redirect';
import { unregister } from '../../../registerServiceWorker';
import { signOutFromALPlus } from 'utils/utilFunctions';


class NetPackWrapper {

    constructor(requestType = 'GET', imagable = false, isFilePayload = false) {
        this._cache = false;
        this._lastImage = null;
        this._imagable = imagable;
        this._requestType = requestType;
        this.dispatch = store.dispatch;
        this.getState = store.getState;
        this.isFilePayload = isFilePayload;
    }

    shouldAjaxCall = () => true

    prepareUrl = (apiInfoObject = {}) => ''

    beforeCall = (apiInfoObject) => apiInfoObject

    parser = (response, apiInfoObject) => response

    afterCall = (response) => response

    successCall = (response) => response

    parseErrorHandler = (parseError) => this.dispatch(parseErrorHandler(parseError))

    backendErrorHandler = (backendError) => this.dispatch(backendErrorHandler(backendError))

    networkErrorHandler = (networkError) => this.dispatch(networkErrorHandler(networkError))

    onError = (error) => error

    internetConnectivityHandler = (status) => this.dispatch(internetConnectivityHandler(status))

    getHandlersForNetpack = () => ({
        parseErrorHandler: this.parseErrorHandler,
        backendErrorHandler: this.backendErrorHandler,
        networkErrorHandler: this.networkErrorHandler,
        internetConnectivityHandler: this.internetConnectivityHandler
    })

    getAccessTokenForDevPhase = () => {
        if (environment.REACT_APP_ENV === 'local') {
            return environment.REACT_APP_AL_ACCESS_TOKEN;
        }
        return null;
    }

    deleteCacheAndLogout = () => {
        if (environment.REACT_APP_ENV != 'local') {
            signOutFromALPlus('/login');
        }
    }

    ifAppHasLoggedOrTimedOut = (errorObject) => {
        if(errorObject && errorObject.ajaxCallInfo && errorObject.ajaxCallInfo.status == 401){
            const possibleMessages = [
                "Looks like you have not loggedin to this service yet."
            ];

            if(possibleMessages.find( possibleMessage => possibleMessage == errorObject.response.message)){
                return true;
            }           
        }
        return false;
    }

    call = (object = {}) => {

        let apiInfoObject = object;

        if (!apiInfoObject.forceCall && !this.shouldAjaxCall(apiInfoObject)) {
            return resolvePromise();
        }

        apiInfoObject = this.beforeCall(apiInfoObject);    

        let url = this.prepareUrl(apiInfoObject);
        
        if (url.indexOf('?') > -1) {
            url += `&limit=${1000}`;
        }
        else {
            url += `?limit=${1000}`;
        }

        if (isIE) {
            let noCacheIE = (1 + Math.random()).toString(36).substr(2, 6);
            if (url.indexOf('?') > -1) {
                url += `&no-cache-ie=${noCacheIE}`;
            }
            else {
                url += `?no-cache-ie=${noCacheIE}`;
            }
        }

        const handlers = this.getHandlersForNetpack();

        const netpackInstance = new NetPack(handlers);

        const ajaxCall = netpackInstance.coreUtil(this._requestType, url, apiInfoObject, this.isFilePayload);

        this._lastImage = ajaxCall;

        return ajaxCall.then((response) => {
            if (this._imagable && ajaxCall !== this._lastImage) {
                const errorObj = {
                    status: 'obsolete'
                };
                return rejectPromise(errorObj);
                ;
            }
            return response;
        })

            .then((response) => this.parser(response, apiInfoObject))

            .then((response) => this.successCall(response, apiInfoObject))

            .then((response) => this.afterCall(response, apiInfoObject))

            .then((response) => resolvePromise(response))

            .catch((errorObject) => {
                console.log('errorObj Netpackwrapper', errorObject, apiInfoObject, url);
                if (errorObject.status !== 'obsolete') {
                    if (this.ifAppHasLoggedOrTimedOut(errorObject)) {
                        this.deleteCacheAndLogout();
                    } else {
                        this.afterCall(errorObject.response, apiInfoObject);
                        this.onError({
                            errorObject,
                            apiInfoObject
                        });
                    }
                }
                return rejectPromise(errorObject);
            });
    }
}

export default NetPackWrapper;
