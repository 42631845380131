/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: BatchContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { setBatchWorkflowStatus } from 'actions/batches/apiActions';
import { fetchCourseForNextActivity } from 'actions/courses/apiActions';
import { updateWorkflowStatus } from 'actions/batches/actionCreators';
import JourneysListItem from 'components/journeys/journeysList/journeysListItem';
import setUiState from 'actions/ui/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { getActiveBatchDetails } from 'utils/batchUtils';
import { updateFetchBatchInvitationStatus } from 'actions/loaders/actionCreators';
import { filterObjectFromArray } from 'utils/objectUtils';
import AppLoader from 'commonComponents/appLoader';
import { updateBatchIdFromRoute } from 'actions/batches/actionCreators';
import { setActiveBatch } from 'actions/batches/apiActions';
import {fetchBatchDetails} from 'actions/batches/newApiActions';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

class BatchInvitationContainer extends React.Component {
	
	constructor(props) {
		super(props);
		const { batchId } = props.match.params;

		if(this.props.isBatchAboutRoute) {
			props.fetchBatchDetails(batchId);
			props.updateBatchIdFromRoute(batchId);
		}
	}

	componentDidMount() {
		this.props.updateFetchBatchInvitationStatus(true);
		//const { batchId } = this.props.match.params;
		//this.props.fetchBatchInvitationData(batchId, false);
		startTracking(true, {
			batchId: this.props.match.params.batchId
		}, 'BatchInvitation');

		const batchDetails = getActiveBatchDetails(this.props.batches);
		document.title = `Journey Invitation - ${batchDetails.getIn(['journey', 'name'])} - Aktivlearn Plus`;

		this.sendSegmentData();
	}

	sendSegmentData = () => {
		let segmentData = {
			...(this.props.match.params),
			url: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.page(
			`Journey Invitation - Batch ID ${this.props.match.params.batchId}`,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	componentWillReceiveProps(nextProps) {
		const { batchId } = nextProps.match.params;
		if (this.props.match.params.batchId != batchId) {
			endTracking();
			startTracking(true, {
				batchId: nextProps.match.params.batchId
			}, 'BatchInvitation');
			//this.props.fetchBatchInvitationData(batchId, true);
		}
	}

	componentWillUnmount() {
		endTracking();
	}

	render() {
		const { batches, loaders, match, userState } = this.props;
		const activeBatchId = batches.get('activeBatchId');
		const batchesList = batches.get('batchesList');
		const userBatchesList = batches.get('userBatchesList');
		const activeUserBatch = filterObjectFromArray(userBatchesList, 'batchId', activeBatchId);

		if (batches.get('isSwitchingBatch')) {
			return <AppLoader userState={this.props.userState} />
		}

		if (activeBatchId === -1 || activeUserBatch.isEmpty() || batchesList.isEmpty() || loaders.get('isFetchingBatchDashboardBasicData') || +match.params.batchId !== activeUserBatch.get('batchId')) {
			return <AppLoader userState={this.props.userState} />
		}

		return <JourneysListItem {...this.props} />;
	}
}

const mapStateToProps = (state) => ({
	batches: state.get('batches'),
	phases: state.get('phases'),
	items: state.get('items'),
	attachmentsList: state.get('attachments').get('list'),
	user: state.get('user'),
	uiState: state.get('uiState'),
	loaders: state.get('loaders'),
	userState: state.get('userState'),
	course: state.get('course'),
	coursesModulesList: state.get('coursesModulesList'),
	modulesItemsList: state.get('modulesItemsList')
});

const mapDispatchToProps = (dispatch) => ({
	setUiState: (payload) => {
		dispatch(setUiState(payload))
	},
	updateWorkflowStatus: (workflowStatus) => {
		dispatch(setBatchWorkflowStatus(workflowStatus));

		// if (process.env.REACT_APP_ENV != 'local') {
		// 	dispatch(setBatchWorkflowStatus(workflowStatus));

		// }
		// else {
		// 	dispatch(updateWorkflowStatus(workflowStatus));
		// }
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},
	fetchCourseForNextActivity: (params) => {
		fetchCourseForNextActivity(params);
	},
	updateFetchBatchInvitationStatus: (status) => {
		dispatch(updateFetchBatchInvitationStatus(status));
	},
	updateBatchIdFromRoute: (batchIdFromRoute) => {
		dispatch(updateBatchIdFromRoute(batchIdFromRoute));
	},
	setActiveBatch: (batchId) => {
		dispatch(setActiveBatch(batchId));
	},
	fetchBatchDetails: (batchId)=>{
		dispatch(fetchBatchDetails(batchId));
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchInvitationContainer));