import React from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

const signature = "UEcydHQxV0ZRZktaWjlCbXdReU1QQS45MzIyNzcyNTE2Ni4xNjQ0OTkzNTk4ODI3LjAuV2w5L3F1OEc1TnB3Skpsa05BMnVEVS9sOUFvV2JVNDRva0pZT1F5Kyt3cz0";
const apiKey = "PG2tt1WFQfKZZ9BmwQyMPA";
const meetingNumber = "93227725166";
const userName = "user2.b952@mailinator.com";
const passWord = "TW2FWj";


export default function VideoChatPOC() {

	const joinCall = () => {
		const client = ZoomMtgEmbedded.createClient();

		let meetingSDKElement = document.getElementById('meetingSDKElement');


		client.init({
			debug: true,
			zoomAppRoot: meetingSDKElement,
			language: 'en-US',
			customize: {
			  meetingInfo: [
				'topic',
				'host',
				'mn',
				'pwd',
				'telPwd',
				'invite',
				'participant',
				'dc',
				'enctype',
			  ],
			  toolbar: {
				buttons: [
				  {
					text: 'Custom Button',
					className: 'CustomButton',
					onClick: () => {
					  console.log('custom button');
					},
				  },
				],
			  },
			},
		  });

		client.join({
			apiKey: apiKey,
			signature: signature, // role in signature needs to be 0
			meetingNumber: meetingNumber,
			password: passWord,
			userName: userName
		})
	}
	
  return (
    <><div>VideoChatPOC</div><div style={{ width: '500px', height: '400px', border: '4px solid green', margin: 'auto' }} id="meetingSDKElement">
			Zoom will render here
		</div>
		<button onClick={joinCall}>Join Meeting</button>
		
		</>
  )
}
