import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import { setModuleItemContentDetails, pushModuleItemToCache } from 'actions/moduleItems/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { replaceWithDevPhaseAPIUrl } from 'utils/apiUrl';
import { setApiError } from 'actions/apiErrors/actionCreators';
import environment from 'utils/environment';
import * as Sentry from '@sentry/browser';

class FetchModuleItemContentDetails extends NetPackWrapper {
  constructor() {
    super('GET', true);
  }

  prepareUrl = apiInfoObject => {

    let url = appendQueryParameters(this.getState().get('moduleItem').get('moduleItem').get('url'), {
      access_token: this.getAccessTokenForDevPhase(),
      include: 'submission',
      batchId: apiInfoObject.batchId,
      phaseId: apiInfoObject.phaseId,
      phaseItemId: apiInfoObject.phaseItemId
    });

    if (environment.REACT_APP_DEV_ENV === 'local') {
      return replaceWithDevPhaseAPIUrl(url);
    }
    return url;

  };

  shouldAjaxCall = (apiInfoObject) => {
    // if(!this.getState().get('moduleItem').get('moduleItem')){
    //   this.dispatch(setModuleItemContentDetails({}));
    //   return false;
    // }

    /* if there is no url to fetch content details */
    if (!this.getState().getIn(['moduleItem', 'moduleItem', 'url'])) {
      this.dispatch(setModuleItemContentDetails({}));
      this.pushModuleItemToCacheAfterFetch({});
      return false;
    }

    return true;
  };

  successCall = (contentDetailsResponse, apiInfoObject) => {
    this.dispatch(setModuleItemContentDetails(contentDetailsResponse));

    /*push it to module item cache */
    this.pushModuleItemToCacheAfterFetch(contentDetailsResponse);

    return contentDetailsResponse;
  }

  onError = (error) => {
    this.dispatch(setApiError(
      'fetchModuleItemError',
      'There was a problem while fetching the content.'
    ));
  }

  pushModuleItemToCacheAfterFetch = (contentDetailsResponse) => {
    try{
      this.dispatch(
        pushModuleItemToCache(this.getState().get('moduleItem').get('moduleItem').toJS(), contentDetailsResponse)
        );
      } catch(error){
        
        console.log('debug: pushModuleItemToCacheAfterFetch');
        console.log(JSON.stringify(this.getState().get('moduleItem').toJS()));
        console.log('debug: moduleItemsCache');
        console.log(JSON.stringify(this.getState().get('moduleItemsCache').toJS()));

        Sentry.captureException({
        error,
        moduleItem: JSON.stringify(this.getState().get('moduleItem').toJS()),
        moduleItemCache: JSON.stringify(this.getState().get('moduleItemsCache').toJS())
      });
    };
  }
}

export default new FetchModuleItemContentDetails();
