import React, { Component } from 'react';
import styles from './moduleItemFullScreen.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import FullScreenIcon from 'svgComponents/fullScreenIcon';
import Close from 'svgComponents/close';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import Dialog from 'commonComponents/dialog';
import SupportIcon from 'svgComponents/supportIcon';
import AppLoader from 'commonComponents/appLoader';
import FullScreen from 'commonComponents/fullScreen';
import environment from 'utils/environment';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { isIPadOrIPhone } from 'utils/browser';
import { isIOS } from 'react-device-detect';
import FreshChatBtn from '../../freshchatBtn/FreshChatBtn';
import { getActiveBatchDetailsWithId, getActiveBatchId } from '../../../util/batchUtils';
import { checkIfValueIsTrue } from "utils/utilFunctions";

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ModuleItemFullScreen extends Component {

    constructor(props) {
        super();
        this.state = {
            isFull: false,
            embedded: props.embedded,
            showFeedback: true,
            isExitPopupOpen: false,
            showLoader: true
        }
    }

    componentWillMount() {
        const { moduleItem } = this.props;
        let supportElement = document.getElementById('freshwidget-button');

        if (supportElement)
            supportElement.style.visibility = 'hidden';

        if (moduleItem && moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed'])) {
            this.setState({
                showFeedback: false
            })
        }
    }

    toggleFeedback = () => {
        this.setState({
            showFeedback: !this.state.showFeedback
        })
    }

    componentWillUnmount() {
        let supportElement = document.getElementById('freshwidget-button');
        if (supportElement)
            supportElement.style.visibility = 'visible';
    }

    onClickOfFullScreen = () => {
        let newState = {
            isFull: !this.state.isFull,
        };
        if (this.props.embedded) {
            newState.embedded = !this.state.embedded;
        }
        this.setState(newState);
    }

    exitFullScreen = () => {
        this.setState({
            isFull: false
        });
    }

    openPopup = () => {
        this.setState({
            isExitPopupOpen: true
        })
    }

    closePopup = () => {
        this.setState({
            isExitPopupOpen: false
        })
    }

    sendSegmentData = () => {
        let segmentData = {
            itemName: this.props.title,
            referrer: window.location.href,
            pathHash: window.location.hash
        }
        window.analytics.track(
            'Item Fullscreen Closed',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onExit = () => {
        this.sendSegmentData()
        if (this.props.embedded) {
            this.setState({
                embedded: true,
                isFull: false,
                isExitPopupOpen: false,
                showLoader: true
            });
            return;
        }
        const { onExit } = this.props;
        onExit();
    }

    getLoader = () => {
        return <AppLoader userState={this.props.userState} />;
    }

    toggleLoader = () => {
        this.setState({
            showLoader: !this.state.showLoader
        });
    }

    onClickOfSupport = () => {
        let supportElement = document.getElementById('freshwidget-button').querySelector('a');
        if (supportElement)
            supportElement.click()
    }

    shouldShowFullscreenButton = () => {        
        //Safari on iPhone and iPad doesn't support fullscreen API
        if(isIPadOrIPhone()){
            return false;
        }
        return true;
    }

    render() {
        const {
            src,
            title,
            getLabel,
            getLabelWithDefaults,
            skinGuide,
            showPlatformExitButton,
            isSimOrAssessment
        } = this.props;

        const { isFull, embedded, isExitPopupOpen, showLoader } = this.state;

        const myStyles = getSkin(skinGuide);

        let fullScreenToggleText = getLabel('fullscreen_label');
        if (isFull) {
            fullScreenToggleText = getLabel('leave_fullscreen_label');
        }

        const activeBatchId = getActiveBatchId();
        const activeBatchDetails = getActiveBatchDetailsWithId(activeBatchId);
        const isFreshchatEnabledSetting = activeBatchDetails.getIn(["journey", "settings", "isFreshchatEnabled"]);
        const isFreshchatEnabled = isSimOrAssessment && isFreshchatEnabledSetting && checkIfValueIsTrue(isFreshchatEnabledSetting) ? true : false;

        return (
            <FullScreen fullscreenEnabled={isFull} onExitFullScreen={this.exitFullScreen}>
                <div styleName={embedded ? "" : "iframe-container"}>
                    {
                        (isExitPopupOpen)
                            ?
                            <div styleName="overlay">
                                <Dialog
                                    key="quiz-confirmation-dialog"
                                    dialogMessage={getLabelWithDefaults('exit_confirmation_message', 'Are you sure you want to exit?')}
                                    okAction={this.onExit}
                                    cancelAction={this.closePopup}
                                    okButtonText={getLabelWithDefaults('exit_confirmation_yes_label', 'Yes')}
                                />
                            </div>
                            :
                            null
                    }

                    {
                        this.shouldShowFullscreenButton() && embedded ?
                            <div styleName="fullscreen-btn-cnt-for-embedded">
                                <div styleName="item" onClick={this.onClickOfFullScreen}>
                                    <FullScreenIcon strokeColor="#000" />
                                    <div className={css(myStyles.textForEmbedded)}>{fullScreenToggleText}</div>
                                </div>
                            </div>

                            :
                            <div styleName={showPlatformExitButton ? "iframe-header": "iframe-header header-no-exit"}>
                                <div styleName="header-left">
                                    {this.shouldShowFullscreenButton() && <div styleName="item" onClick={this.onClickOfFullScreen}>
                                        <FullScreenIcon />
                                        <div className={css(myStyles.text)}>{fullScreenToggleText}</div>
                                    </div>}
                                </div>
                                {
                                    !isLaunchedFromExternalIntegration()
                                    && environment.REACT_APP_ENABLE_FRESHDESK === 'true'
                                    &&
                                    <div styleName="support-btn-cnt" onClick={this.onClickOfSupport}>
                                        <div styleName="support-image-cnt">
                                            <SupportIcon />
                                        </div>
                                        <div className={css(myStyles.supportBtnText)}>
                                            {getLabel('freshdesk_support_label').toLowerCase()}
                                        </div>
                                    </div>
                                }

                                {
                                    isFreshchatEnabled && <FreshChatBtn activeBatchId={activeBatchId} activeBatchDetails={activeBatchDetails}/>
                                }

                                {showPlatformExitButton && <div styleName="header-right" onClick={this.openPopup}>
                                    <div styleName="icon"><Close /></div>
                                    <div className={css(myStyles.text)}>{getLabel('exit_label')}</div>
                                </div>}
                            </div>
                    }

                    <div styleName="product">
                        <div styleName={embedded ? "embedded-iframe-cnt" : ""}
                        >
                            {
                                showLoader && <div styleName="loader-cnt">
                                    {this.getLoader()}
                                </div>
                            }

                            {
                                src ? <iframe
                                    src={src}
                                    styleName={embedded ? "embedded-iframe" : (isIOS ? 'resp-container-iphone' : 'resp-container')}
                                    onLoadStart={isSimOrAssessment ? this.toggleLoader : null}
                                    allowFullScreen
                                    allow="camera *; microphone *; autoplay"
                                    title={title}
                                />
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </FullScreen>

        );
    }
}

ModuleItemFullScreen.defaultProps = {
    showPlatformExitButton: true
}

export default ModuleItemFullScreen;
