import React from 'react';
import PrimaryButton from 'commonComponents/buttons/primaryButton';

export default function ZoomClientPOC() {

  const openZoomClient = () => {
    window.open(`${window.location.origin}/zoom/index.html?leaveUrl=${window.location.href}`)
  }
	
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      height: '400px',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <PrimaryButton text="Launch Zoom Meeting" clickFunction={openZoomClient}/>
    </div>
  )
}
