import FetchItemDetails from 'actions/serverCalls/items/FetchItemDetails';
import FetchItemLaunchDetails from 'actions/serverCalls/items/FetchItemLaunchDetails';
import FetchToolFAQs from 'actions/serverCalls/tools/FetchToolFAQs';
import { fetchBatchDetails, fetchBatchDetailsForItem } from 'actions/batches/newApiActions';

import SetItemWorkflowStatus from 'actions/serverCalls/items/SetItemWorkflowStatus';

import FetchUserPhases from 'actions/serverCalls/phases/FetchUserPhases';
import FetchUserItems from 'actions/serverCalls/items/FetchUserItems';
import { filterObjectFromArray } from 'utils/objectUtils';
import { resolvePromise, rejectPromise } from 'utils/promise';
import { setPhaseWorkflowStatusThunk } from 'actions/phases/apiActions';
import { updateFetchItemDataStatus } from 'actions/loaders/actionCreators';
import enumConstants from 'constants/enums';
import routes from 'utils/webAppRoutes/routeConstants';
import {
    fetchItemFeedbackThunk,
    fetchUserItemFeedbackThunk
} from 'actions/itemFeedback/apiActions';
import AssessmentItemValidator from 'utils/validators/AssessmentItemValidator';
import AssessmentItemFeedbackValidator from 'utils/validators/AssessmentItemFeedbackValidator';
import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';
import { setBatchWorkflowStatus } from 'actions/batches/apiActions';
import { getNextPhaseItem } from 'utils/phaseItemUtils';
import FetchNextActivityItem from 'actions/serverCalls/moduleItems/FetchNextActivityItem';
import FetchCourseWithModules from 'actions/serverCalls/courses/FetchCourseWithModules';
import FetchItemProgress from 'actions/serverCalls/items/FetchItemProgress';
import FetchAttachmentDetails from 'actions/serverCalls/attachments/FetchAttachmentDetails';
import store from 'store/store';

const fetchItemDataThunk = (dispatch, getState, batchId, itemId, shouldFetchBaseData = true) => {
    dispatch(updateFetchItemDataStatus(true));
    return fetchBatchDetailsForItem(dispatch, batchId, shouldFetchBaseData).then(() => {
            const userItemsList = getState().get('items').get('userItemsList');
            const currentUserItem = filterObjectFromArray(userItemsList, 'id', itemId);
            const currentItemStatus = currentUserItem.get('workflowState');

            const itemsList = getState().get('items').get('itemsList');
            const currentItem = filterObjectFromArray(itemsList, 'id', itemId);
            const itemPhaseId = currentItem.get('phaseId');
            const itemBatchId = currentItem.get('batchId');

            const promisesForFetch = [];

            //check and update item status
            if (currentItemStatus && currentItemStatus == enumConstants.get('UNOPENED')) {
                promisesForFetch.push(
                    setItemWorkflowStatusThunk(dispatch, getState, enumConstants.get('OPENED'))
                );
            }

            //check and update phase status
            if (itemPhaseId) {
                const userPhasesList = getState().get('phases').get('userPhasesList');
                const currentUserPhase = filterObjectFromArray(userPhasesList, 'id', itemPhaseId);
                const currentPhaseStatus = currentUserPhase.get('workflowState');
                const routeObj = {};
                const failureRouteObj = {
                    route: routes.get('BATCH_HOME'),
                    params: [{ BATCH_ID: itemBatchId }]
                };

                if (currentPhaseStatus && currentPhaseStatus == enumConstants.get('NOT_STARTED')) {
                    promisesForFetch.push(
                        setPhaseWorkflowStatusThunk(
                            dispatch, getState, batchId, itemPhaseId, enumConstants.get('STARTED'), routeObj, failureRouteObj
                        )
                    );
                }
            }

            if (promisesForFetch.length !== 0) {
                return Promise.all(promisesForFetch);
            }

            return resolvePromise();
        })
        .then(() => {
            const itemApiInfoObject = {
                itemId,
                queryParameters: {
                    batchId
                }
            };
            return Promise.all([
                FetchItemDetails.call(itemApiInfoObject),
                // FetchItemMetricsDetails.call(itemApiInfoObject)
            ]);
        })
        .then(() => {
            return getFAQ(getState, itemId);
        })
        .then((response) => {
            dispatch(updateFetchItemDataStatus(false));
            return resolvePromise(response);
        })
        .catch((response) => {
            dispatch(updateFetchItemDataStatus(false));
            return rejectPromise(response);
        });
}

const getFAQ = (getState, itemId) => {
    const batchesList = getState().get('batches').get('batchesList');
    const batchId = getState().get('userState').get('currentBatchId');
    const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);
    const lang = activeBatchDetails.get('journey').get('lang');

    const itemsList = getState().get('items').get('itemsList');
    const currentItem = filterObjectFromArray(itemsList, 'id', itemId);
    const toolId = currentItem.get('toolId');

    return new Promise((resolve, reject) => {
        FetchToolFAQs.call({ toolId, lang, shouldRetry: false })
            .then((response) => resolve(response))
            .catch((error) => resolve());
    })
}

const getValidationObject = (itemId) => {
    return {
        ids: { itemId }
    }
};

const fetchItemData = (batchId, itemId, shouldFetchBaseData = true) => (dispatch, getState) =>
    AssessmentItemValidator.preValidate(getValidationObject(itemId))
        .then(response => fetchItemDataThunk(dispatch, getState, batchId, itemId, shouldFetchBaseData))
        .then(response => AssessmentItemValidator.postValidate(getValidationObject(itemId)));


const getReturnUrl = (batchId, itemId, isTalentPulseLaunch = false) => {
    if(isTalentPulseLaunch){
        return `${window.location.protocol}//${window.location.host}/batch/${batchId}/item/${itemId}`;
    }
    return window.location.protocol + '//' + window.location.host + '/iframeClose.html';
}

const fetchProductLaunchData = (isEmbeddedLaunch = false, isTalentPulseLaunch = false) => (dispatch, getState) => {
    const itemId = getState().get('items').get('itemIdFromRoute');
    const batchId = getState().get('userState').get('currentBatchId');
    const route = getReturnUrl(batchId, itemId, isTalentPulseLaunch);
    const returnURL = encodeURIComponent(route);
    const productLaunchApiInfoObject = {
        itemId,
        isEmbeddedLaunch,
        isTalentPulseLaunch,
        queryParameters: {
            batchId,
            returnURL
        }
    };

    return FetchItemLaunchDetails.call(productLaunchApiInfoObject);
}

const setItemWorkflowStatusThunk = (dispatch, getState, workflowState) => {
    const itemId = getState().get('userState').get('currentItemId');
    const batchId = getState().get('userState').get('currentBatchId');

    /* Mark batch STARTED if not already started*/
    const userBatchesList = getState().get('batches').get('userBatchesList');
    const activeUserBatchDetails = filterObjectFromArray(userBatchesList, 'batchId', batchId);

    if(workflowState == enumConstants.get('STARTED') && activeUserBatchDetails.get('workflowState') == enumConstants.get('INVITED')){
        dispatch(setBatchWorkflowStatus(enumConstants.get('ACCEPTED')));
    }

    return SetItemWorkflowStatus.call({
        itemId,
        queryParameters: {
            batchId
        },
        body: {
            workflowState
        }
    }).then(
        FetchUserItems.call({ queryParameters: { batchId } })
    );
}

const setItemWorkflowStatus = (workflowState) => (dispatch, getState) =>
    setItemWorkflowStatusThunk(dispatch, getState, workflowState);

const fetchItemDataForFeedback = (batchId, itemId, scenario, entityType) => (dispatch, getState) => {
    dispatch(updateFetchFeedbackStatus(true));
    return AssessmentItemFeedbackValidator.preValidate(getValidationObject(itemId))
        .then(() => dispatch(fetchBatchDetails(batchId)))
        .then(() => {
            let feedbackScenario = scenario;
            const selectedItem = getState().getIn(['items', 'itemsList']).find(item => item.get('id') === +itemId)
            if (
                selectedItem
                && selectedItem.getIn(['settings', 'feedbackScenarioId'])
                && selectedItem.getIn(['settings', 'isFeedbackEnabled'])
                && selectedItem.getIn(['settings', 'isFeedbackEnabled']) === 'true'
            ) {
                feedbackScenario = selectedItem.getIn(['settings', 'feedbackScenarioId'])
                fetchItemFeedbackThunk(dispatch, getState, itemId, feedbackScenario)
            }
        })
        .then(() => {
            let feedbackScenario = scenario;
            const selectedItem = getState().getIn(['items', 'itemsList']).find(item => item.get('id') === +itemId)

            if (
                selectedItem
                && selectedItem.getIn(['settings', 'feedbackScenarioId'])
                && selectedItem.getIn(['settings', 'isFeedbackEnabled'])
                && selectedItem.getIn(['settings', 'isFeedbackEnabled']) === 'true'
            ) {
                feedbackScenario = selectedItem.getIn(['settings', 'feedbackScenarioId'])
                fetchUserItemFeedbackThunk(dispatch, getState, batchId, itemId, entityType, feedbackScenario)
            }
        })
        .then(() => {
            const itemApiInfoObject = {
                itemId,
                queryParameters: {
                    batchId
                }
            };
            return FetchItemDetails.call(itemApiInfoObject)
        })
        .then(() => {
            return getFAQ(getState, itemId);
        })
        .then(() => AssessmentItemFeedbackValidator.postValidate(getValidationObject(itemId)))
    // .catch((a) => console.error(a));
}

const fetchNextItemRouteParams = (batchId, currentPhaseId, currentPhaseItemId) => {
    return async (dispatch, getState) => {

        const batchesList = getState().get('batches').get('batchesList');
        const currentBatch = filterObjectFromArray(batchesList, 'id', batchId);
        const phasesList = getState().get('phases').get('phasesList');
        const journeyPhasesList = phasesList.filter(item => item.get('journeyId') === currentBatch.getIn(['journey', 'id']));
        const itemsList = getState().get('items').get('itemsList');
        const journeyItemsList = itemsList.filter(item => item.get('journeyId') === currentBatch.getIn(['journey', 'id']));
        const nextPhaseItem = getNextPhaseItem(currentPhaseId, currentPhaseItemId, journeyPhasesList, journeyItemsList);


        if(!nextPhaseItem) return null; // if current phase item is last item of the journey
        
        const params = {
            batchId,
            phaseId: nextPhaseItem.get('phaseId'),
            phaseItemId: nextPhaseItem.get('id'),
            courseId: nextPhaseItem.get('toolId')
        };
        
        //doing this to make double sure the next item is unlocked if it should be on completing this prerequisite
        await FetchUserPhases.call({ batchId });
        await FetchUserItems.call({ queryParameters: { batchId } });

        const userItemsList = getState().get('items').get('userItemsList');
        const nextUserItem = filterObjectFromArray(userItemsList, 'id', nextPhaseItem.get('id'));
        let nextItemResponse = { ...params, locked: nextUserItem.getIn(['lock_details', 'locked']), type: nextPhaseItem.get('type') };

        if(nextPhaseItem.get('type') === 'COURSE'){
            const nextALCourse = await FetchCourseWithModules.call({ ...params, needOnlyBasicDetails: true});
            if(nextALCourse.course_type === 'scorm') return null; // for scorm courses take user back to dashboard, because it is launched there
    
            //this gets the next AL course's active module id and module item id
            const nextCourseModuleItemDetails = await FetchNextActivityItem.call(params);
    
            nextItemResponse = {
                ...nextItemResponse,
                moduleId: nextCourseModuleItemDetails.context_module_id,
                moduleItemId: nextCourseModuleItemDetails.id
            };
        }

        return nextItemResponse;
    }
}

const fetchItemProgress = (itemId, batchId) => {
    FetchItemProgress.call({
        itemId,
        queryParameters: {
            batchId
        }
    })
}

const fetchNextPhaseItemDetailsAndProgress = async (currentItem, currentBatchId) => {
    const itemsList = store.getState().get('items').get('itemsList');
    console.log('debugatt currentItem', currentItem&&currentItem.toJS())
    console.log('debugatt itemsList', itemsList && itemsList.toJS())
    const nextItem = itemsList.filter(
		item => (item.get('order') > currentItem.get('order') && item.get('phaseId') === currentItem.get('phaseId'))
	).get(0);

    console.log('debugatt next', nextItem && nextItem.toJS());

    if(nextItem){
        if(nextItem.get('type') === 'ATTACHMENT'){
            await FetchAttachmentDetails.call({ attachmentId: nextItem.get('attachmentId') });
        }
        fetchItemProgress(nextItem.get('id'), currentBatchId);
    }
}

const refreshPhasesAndPhaseItems = (batchId) => {
    FetchUserItems.call({ queryParameters: { batchId } });
    FetchUserPhases.call({batchId });    
}

export {
    fetchItemData,
    fetchProductLaunchData,
    setItemWorkflowStatus,
    fetchItemDataForFeedback,
    fetchItemProgress,
    fetchNextPhaseItemDetailsAndProgress,
    refreshPhasesAndPhaseItems,
    fetchNextItemRouteParams
};