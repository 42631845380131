/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: header.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Single point source for enum constants
 *
 **/

import Immutable from 'immutable';

const enums = Immutable.Map({
	UNOPENED: 'UNOPENED',
	OPENED: 'OPENED',
	STARTED: 'STARTED',
	COMPLETED: 'COMPLETED',
	NOT_STARTED: 'NOT_STARTED',

	ACCEPTED: 'ACCEPTED',
	INVITED: 'INVITED',

	PENDING: 'PENDING',
	EXPIRED: 'EXPIRED',
	LOCKED: 'LOCKED',

	ASSESSMENT: 'Assessment',
	DEVELOPMENT: 'Development',
	LEARNING: 'Learning',
	MODULE_ITEMS: 'MODULE_ITEMS',
	MODULE: 'MODULE',

	COMPETENCY: 'COMPETENCY',
	VALUES: 'VALUES',

	SIMULATION: 'SIMULATION',
	GAME: 'GAME',
	HARRISON_ASSESSMENTS: 'HARRISON_ASSESSMENTS',
	TALENT_PULSE_ASSESSMENT: 'TALENT_PULSE_ASSESSMENT',
	ATTACHMENT: 'ATTACHMENT',
	CONTENT: 'CONTENT',

	SELECTED: 'SELECTED',

	COURSE: 'COURSE',
	// MODULE_ITEMS: 'MODULE_ITEMS',

	FEEDBACK_THUMBS_UP_DOWN: 'THUMBS_UP_DOWN',
	FEEDBACK_MAQ: 'MAQ',
	FEEDBACK_RATING: 'RATING',
	FEEDBACK_INPUT_MEDIUM_TEXT: 'INPUT_MEDIUM_TEXT',
	FEEDBACK_RATING_SCALE: 'RATING_SCALE',
	RATING_SCALE: 'RATING_SCALE',

	ENTITY_TYPE_PHASE: 'PHASE',
	SCENARIO_PHASE: 'PHASE',
	SCENARIO_TOOLS: 'TOOLS',
	ENTITY_TYPE_PHASE_ITEM: 'PHASEITEM',
	SCENARIO_CONTENT: 'CONTENT',

	SCENARIO_ASSESSMENT_PHASE: 'ASSESSMENT_PHASE',
	ENTITY_TYPE_ASSESSMENT_PHASE: 'PHASE',
	SCENARIO_COURSE: 'REGULAR_COURSE',
	ENTITY_TYPE_COURSE: 'PHASEITEM',

	PAGE: 'PAGE',
	QUIZ: 'QUIZ'

});

export default enums;