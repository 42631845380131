import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './JourneySwitcher.module.sass';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { StyleSheet, css } from 'aphrodite/no-important';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { textTruncate, checkIfPresent } from 'utils/utilFunctions';
import BatchesListLoader from 'commonComponents/loaders/batchesListLoader';
import routes from 'utils/webAppRoutes/routeConstants';
import CircularProgressBar from 'commonComponents/circularProgressBar';
import Lock from 'images/icons/lock.svg';
import Expired from 'images/icons/expired.svg';
import Dialog from 'commonComponents/dialog'

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneySwitcher extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			batchToReset: null,
			userId: null,
			showResetConfirmationPopup: false,
			resetPopupMessage: '',
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		}
	}

	componentDidMount() {
		this.props.fetchBatchesList();
	}

	componentWillUnmount() {
		this.setState({
			showResetConfirmationPopup: false,
			resetPopupMessage: '',
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		})
	}

	onClickAboutJourney = () => {
		this.props.setUiState({
			showOverlay: true,
			showAboutJourney: true,
			openDropDown: false,
			overlayOpacity: 0.5
		});
	}

	renderIcon = (userDetails) => {

		const batchLockDetails = userDetails[0].lock_details;
		const isBatchLocked = batchLockDetails.locked && batchLockDetails.lock_reason == 'not_started';
		const isBatchExpired = batchLockDetails.lock_reason == 'expired';

		if (isBatchExpired) {
			return <img src={Expired} />;
		}

		if (isBatchLocked) {
			return <img src={Lock} />;
		}

		let percentageCompletion = 0;

		if (checkIfPresent(userDetails[0].percentageCompletion)) {
			percentageCompletion = userDetails[0].percentageCompletion;
		}

		return <CircularProgressBar
			value={Math.round(percentageCompletion, 2)}
		/>
	}

	onClickOkResetJourney = () => {
		this.setState({
			resetPopupMessage: "Resetting journey data...",
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		}, () => {
			this.props.resetBatchProgressForUser(this.state.batchToReset.id, this.state.userId);
		})
	}

	onClickCancelResetJourney = () => {
		this.setState({
			showResetConfirmationPopup: false,
			resetPopupMessage: '',
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		})
	}

	onClickResetJourney = (batch, userId) => {
		this.setState({
			batchToReset: batch,
			userId: userId,
			showResetConfirmationPopup: true,
			resetPopupMessage: this.props.getLabel('reset_journey_confirmation_message'),
			resetPopupOkAction: this.onClickOkResetJourney,
			resetPopupCancelAction: this.onClickCancelResetJourney
		})
	}
	renderIcon = (userDetails) => {

		const batchLockDetails = userDetails[0].lock_details;
		const isBatchLocked = batchLockDetails.locked && batchLockDetails.lock_reason == 'not_started';
		const isBatchExpired = batchLockDetails.lock_reason == 'expired';

		if (isBatchExpired) {
			return <img src={Expired} />;
		}

		if (isBatchLocked) {
			return <img src={Lock} />;
		}

		let percentageCompletion = 0;

		if (checkIfPresent(userDetails[0].percentageCompletion)) {
			percentageCompletion = userDetails[0].percentageCompletion;
		}
		return <CircularProgressBar
			value={Math.round(percentageCompletion, 2)}
		/>
	}

	renderJourneys(batchesList, userBatchesList, isCurrentBatch, myStyles) {
		return batchesList.map((batch) => {
			const userDetails = userBatchesList.filter((userBatch) => {
				return (userBatch.batchId === batch.id);
			});
			if (userDetails.length <= 0) {
				return null;
			}

			let percentageCompletion = 0;

			if (checkIfPresent(userDetails[0].percentageCompletion)) {
				percentageCompletion = userDetails[0].percentageCompletion;
			}

			return (
				<div
					className={isCurrentBatch ? css(myStyles.individualJourneyActive) : css(myStyles.individualJourney)}
					styleName="individual-journey"
					onClick=
					{
						!isCurrentBatch
							? () => {
								const route = routes.get('BATCH');
								const params = [{ 'BATCH_ID': batch.id }];
								updateRoute({ route, params });
								this.props.setUiState({
									showOverlay: false
								});
								// this.props.setUserState({
								// 	currentBatchId: batch.id
								// });
							}
							: () => {
								this.props.setUiState({
									showOverlay: false
								});
							}
					}
				>
					<div styleName="individual-journey-title-container">
						<div styleName="individual-journey-progress-bar">
							{this.renderIcon(userDetails)}
						</div>
						<div className={css(myStyles.individualJourneyTitle)} styleName="individual-journey-title">
							{this.props.getLabel(batch.journey.name)}
						</div>
						<div className={css(myStyles.individualJourneyPercCompleted)} styleName="individual-journey-perc-completion">
							{Math.round(percentageCompletion)}% {this.props.getLabel('completed_label')}
						</div>
					</div>
					<div
						className={css(myStyles.individualJourneyDesc)} styleName="individual-journey-desc"
						dangerouslySetInnerHTML={{ __html: textTruncate(this.props.getLabel(batch.journey.description), 100) }}
					>
						{/* {textTruncate(this.props.getLabel(batch.journey.description), 100)} */}
					</div>
					{
						isCurrentBatch
							? <div styleName="about-journey-container">
								<div
									onClick={
										(e) => {
											e.stopPropagation();
											this.onClickAboutJourney();
										}
									}
									className={css(myStyles.aboutJourneyButton)}
									styleName="about-journey-button"
								>
									{this.props.getLabel('about_journey_label')}
								</div>
								{
									batch.journey && batch.journey.settings && batch.journey.settings.isResetEnabled === 'true' &&
									<div
										onClick={
											(e) => {
												e.stopPropagation();
												this.onClickResetJourney(batch, userDetails[0].userId);
											}
										}
										className={css(myStyles.aboutJourneyButton)}
										styleName="about-reset-button"
									>
										{this.props.getLabelWithDefaults('reset_journey_label', "Reset Journey")}
									</div>
								}
							</div>
							: null
					}
				</div>
			);
		});
	}

	renderContentLoader() {
		return (
			<BatchesListLoader />
		);
	}

	renderFetchMoreLoader(myStyles) {
		if (!this.props.loaders.isFetchingBatchesList) {
			return null;
		}
		return (
			<div
				className={css(myStyles.individualJourney)}
				styleName="individual-journey-loader"
			>
				{this.renderContentLoader()}
			</div>
		);
	}

	renderLoader(myStyles) {
		return (
			<div
				className={css(myStyles.headerContainer)}
				styleName="journey-switcher-container"
			>
				<div className={css(myStyles.arrowContainer)} styleName="arrow-container">
					<div className={css(myStyles.arrowUp)} styleName="arrow-up"></div>
				</div>
				<div className={css(myStyles.journeysContainer)} styleName="journeys-container">
					{this.renderContentLoader()}
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		// console.log(this.props);
		const {
			showResetConfirmationPopup,
			resetPopupMessage,
			resetPopupOkAction,
			resetPopupCancelAction
		} = this.state;
		if (this.props.batchesList.size === 0) {
			return this.renderLoader(myStyles);
		}
		const currentBatch = this.props.batchesList.filter((batch) => {
			return batch.id === this.props.activeBatchId;
		});
		const otherBatches = this.props.batchesList.filter((batch) => {
			return batch.id !== this.props.activeBatchId;
		});
		const userBatchesList = this.props.userBatchesList;
		return (
			<div
				className={css(myStyles.headerContainer)}
				styleName="journey-switcher-container"
			>
				<div className={css(myStyles.arrowContainer)} styleName="arrow-container">
					<div className={css(myStyles.arrowUp)} styleName="arrow-up"></div>
				</div>
				<div className={css(myStyles.journeysContainer)} styleName="journeys-container">
					{this.renderJourneys(currentBatch, userBatchesList, true, myStyles)}
					{this.renderJourneys(otherBatches, userBatchesList, false, myStyles)}
					{this.renderFetchMoreLoader(myStyles)}
				</div>
				{
					showResetConfirmationPopup &&
					<Dialog
						dialogMessage={resetPopupMessage}
						okAction={resetPopupOkAction}
						cancelAction={resetPopupCancelAction}
						okButtonText={this.props.getLabel('reset_journey_yes_label', 'Yes, reset')}
						cancelButtonText={this.props.getLabel('reset_journey_cancel_label', 'No')}
					/>
				}
			</div>
		);
	}
}

JourneySwitcher.propTypes = {};
JourneySwitcher.defaultProps = {};

export default JourneySwitcher;