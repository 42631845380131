import React from 'react';
import { Route, Switch } from 'react-router-dom';

//import BatchContainer from 'containers/batch/BatchContainer';
import FeedbackContainer from 'containers/FeedbackContainer';
import AssesmentsContainer from 'containers/AssesmentsContainer';
import MeasuresContainer from 'containers/MeasuresContainer';
import PhaseFeedbackContainer from 'containers/PhaseFeedbackContainer';


import ModuleItemContainer from 'containers/ModuleItemContainer';
//import CoursesContainer from '../containers/CoursesContainer';

import CourseFeedbackContainer from 'containers/CourseFeedbackContainer';

import TakeQuizContainer from 'containers/TakeQuizContainer';
import ReviewQuizContainer from 'containers/ReviewQuizContainer';

import MSTeamsLeaderboardContainer from 'containers/MSTeamsLeaderboardContainer';
import MSTeamsLeaderboardConfigure from 'components/msTeamsLeaderboard/Configure';
import MSTeamsLeaderboardRemove from 'components/msTeamsLeaderboard/Remove';

import UserJourneysContainer from 'containers/UserJourneysContainer';
import UserProfileContainer from 'containers/UserProfileContainer';
import UserDownloadsContainer from 'containers/UserDownloadsContainer';

import JourneyCatalogContainer from 'containers/JourneyCatalogContainer';
import TakeSurveyQuizContainer from 'containers/TakeSurveyQuizContainer';
import BatchInvitationContainer from '../containers/batch/BatchInvitationContainer';

import {
	fetchOtherBundles,
	AsyncBatchContainer,
	AsyncAnalyticsContainer,
	AsyncAnalyticsActivityContainer,
	AsyncAnalyticsPerformanceContainer,
	AsyncUserProfileContainer
} from './loadableUtils';

//fetchOtherBundles(3000);

import DailyCoPOC from 'commonComponents/pocs/DailyCoPOC';
import ZoomClientPOC from 'commonComponents/pocs/ZoomClientPOC';
import ZoomComponentPOC from 'commonComponents/pocs/ZoomComponentPOC';

const DesktopRouter = (propsFromParent) => (
	<Switch>
		<Route exact path="/batch/:batchId" component={AsyncBatchContainer} />
		<Route exact path="/batch/:batchId/about" render={(props) => (
			<BatchInvitationContainer {...props} isBatchAboutRoute={true} />
		)}/>
		<Route exact path="/batch/:batchId/item/:itemId" component={AssesmentsContainer} />
		<Route exact path="/batch/:batchId/item/:itemId/feedback" component={FeedbackContainer} />
		<Route exact path="/batch/:batchId/phase/:phaseId/measures" component={MeasuresContainer} />
		<Route exact path="/batch/:batchId/phase/:phaseId/feedback" component={PhaseFeedbackContainer} />

		<Route exact path="/batch/:batchId/analytics" component={AsyncAnalyticsContainer} />
		<Route exact path="/batch/:batchId/analytics/activity" component={AsyncAnalyticsActivityContainer} />
		<Route exact path="/batch/:batchId/analytics/performance" component={AsyncAnalyticsPerformanceContainer} />

		<Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/modules/:moduleId/items/:moduleItemId" component={ModuleItemContainer} />

		<Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/course_feedback" component={CourseFeedbackContainer} />
		<Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/scorm_course_feedback"
			render={(props) => (
				<CourseFeedbackContainer {...props} isScormCourse={true} />
			)}
		/>

		{/* <Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/modules/:moduleId/sc_content/:moduleItemId"
			render={(props) => (
				<ModuleItemContainer {...props} isScormCourse={true} />
			)}
		/> */}

		<Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/modules/:moduleId/items/:moduleItemId/take" component={TakeQuizContainer} />

		<Route exact path="/batch/:batchId/item/:phaseItemId/:courseId/:phaseId/modules/:moduleId/items/:moduleItemId/review/:quizSubmissionAttempt" component={ReviewQuizContainer} />

		<Route exact path="/home"
			render={(props) => (
				<UserJourneysContainer {...props} selectedTab="my_journeys" />
			)}
		/>
		<Route exact path="/home/catalogue"
			render={(props) => (
				<UserJourneysContainer {...props} selectedTab="journey_catalogue" />
			)}
		/>

		<Route exact path="/journeys"
			render={(props) => (
				<UserJourneysContainer {...props} selectedTab="my_journeys" />
			)}
		/>
		<Route exact path="/journeys/catalogue"
			render={(props) => (
				<UserJourneysContainer {...props} selectedTab="journey_catalogue" />
			)}
		/>

		<Route exact path="/catalog/:orgCode" component={JourneyCatalogContainer} />

		<Route exact path="/batch/:batchId/msteams_leaderboard" component={MSTeamsLeaderboardContainer} />

		<Route exact path="/msteams_leaderboard_configure" component={MSTeamsLeaderboardConfigure} />
		<Route exact path="/batch/:batchId/msteams_leaderboard_remove" component={MSTeamsLeaderboardRemove} />
		{/* <Route render={(props) => <ErrorContainer {...props} {...propsFromParent} />} /> */}
		<Route exact path="/profile" component={AsyncUserProfileContainer} />
		<Route exact path="/downloads" component={UserDownloadsContainer} />
		<Route exact path="/change_password"
			render={(props) => (
				<AsyncUserProfileContainer {...props} resetPassword={true} />
			)}
		/>

		<Route exact path="/dailyco-poc" component={DailyCoPOC} />
		<Route exact path="/zoom-client-poc" component={ZoomClientPOC} />
		<Route exact path="/zoom-component-poc" component={ZoomComponentPOC} />
		<Route exact path="/batch/:batchId/item/:phaseItemId/takeQuiz" component={TakeSurveyQuizContainer} />

	</Switch>
)

export default DesktopRouter;