import FetchBatches from 'actions/serverCalls/batches/FetchBatches';
import FetchUserBatches from 'actions/serverCalls/batches/FetchUserBatches';
import { resolvePromise, rejectPromise } from 'utils/promise';

import {
    updateFetchBatchesListStatus
} from 'actions/loaders/actionCreators';


import SetActiveBatch from 'actions/serverCalls/batches/SetActiveBatch';
import SetBatchWorkflowStatus from 'actions/serverCalls/batches/SetBatchWorkflowStatus';
import FetchUserBatchDetails from 'actions/serverCalls/batches/FetchUserBatchDetails';
import ResetBatchProgressForUser from 'actions/serverCalls/batches/ResetBatchProgressForUser';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { scrollToTop } from 'utils/webAppRoutes/validateRoute';
import FetchCourses from 'actions/serverCalls/courses/FetchCourses';
import enumConstants from 'constants/enums';

import FetchUserPhases from 'actions/serverCalls/phases/FetchUserPhases';
import FetchUserItems from 'actions/serverCalls/items/FetchUserItems';
/*Note: major functionality moved to ./newApiActions*/

const fetchBatchesList = () => (dispatch, getState) => {
    dispatch(updateFetchBatchesListStatus(true));
    return FetchUserBatches.call({ showErrorPage: false })
        .then(() => {
            const userBatchesList = getState().get('batches').get('userBatchesList');
            const batchIdsList = userBatchesList.map((userBatch) => userBatch.get('batchId'));
            const batchIdsArray = batchIdsList.toJS();
            const batchesListApiInfoObject = {
                batchIdsArray
            }
            return FetchBatches.call(batchesListApiInfoObject);
        })
        .then((response) => {
            dispatch(updateFetchBatchesListStatus(false));
            return resolvePromise(response);
        })
        .catch((response) => {
            dispatch(updateFetchBatchesListStatus(false));
            return rejectPromise(response);
        });
}

const setActiveBatch = (batchId) => (dispatch, getState) => SetActiveBatch.call({
    batchId,
    shouldRetry: false
});

const setBatchWorkflowStatus = (workflowState) => (dispatch, getState) => {
    const batchId = getState().get('batches').get('activeBatchId');
    return SetBatchWorkflowStatus.call({
        batchId,
        shouldRetry: false,
        body: {
            workflowState
        }
    })
        .then(() => {
            scrollToTop();
            return FetchUserBatchDetails.call({ batchId })
        });
}


const resetBatchProgressForUser = (batchId, userId) => (dispatch, getState) => {
    let payload = {
        batchId,
        userId
    }
    ResetBatchProgressForUser.call(payload).then((response) => {
        const route = routes.get('HOME')
        updateRoute({ route });
    });
}

const refreshUserDataInBatch = (params) => {
    FetchCourses.call(params);
    FetchUserItems.call({ queryParameters: { batchId: params.batchId } });
    FetchUserPhases.call({batchId: params.batchId});         
    FetchUserBatchDetails.call({ batchId: params.batchId });
}

const pollUserItemCompletion = async (phaseItemId, params) => {
    
    const interval = 1000;
    const maxAttempts = 10;

    let attempts = 0;

    const validate = (userItems, phaseItemId) => {
        const userItem = userItems.items.find((eachItem) => {
            return (eachItem.id === phaseItemId);
        });

        if(userItem.workflowStatus == enumConstants.get('COMPLETED')){
            return true;
        }

        return false;
    }
  
    const executePoll = async (resolve, reject) => {
      const userItems = await FetchUserItems.call({ queryParameters: { batchId: params.batchId } });
      
      attempts++;
  
      if (validate(userItems, phaseItemId)) {
        return resolve(userItems);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error('Exceeded max attempts'));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    };
  
    return new Promise(executePoll);
  };

export {
    fetchBatchesList,    
    setActiveBatch,
    setBatchWorkflowStatus,
    resetBatchProgressForUser,
    refreshUserDataInBatch,
    pollUserItemCompletion
}