//import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppProvider from './AppProvider';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { initializeMSTeams, getContext } from 'utils/msTeams';
import { isMSTeams } from 'utils/msTeamsUI';
import { initializeSentry } from 'utils/sentry';
import { initializeFirebaseApp } from 'utils/firebase';
import analytics from 'utils/segment';
import { initializeMixpanel } from 'utils/mixpanelTracking';
import Loader from './Loader';
import { handleHashRoute } from 'utils/webAppRoutes/updateRoute';

handleHashRoute();
initializeSentry();
initializeMixpanel();
initializeMSTeams();

console.log('this is staging env');
// Initialize segment app integration with key
analytics.default.load(process.env.REACT_APP_SEGMENT_KEY);

//fix for scroll issues on tablets and mobiles
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

if (window.location.pathname === "/msteampersonaltab") {
	unregister();
	ReactDOM.render(<Loader />, document.getElementById('root'));
	getContext();
} else {

	if (isMSTeams()) {
		unregister();
	} else {
		registerServiceWorker();
	}

	const rootElement = document.getElementById('root');
	ReactDOM.render(<AppProvider />, rootElement);
	initializeFirebaseApp();

	if (module.hot) {
		module.hot.accept('./AppProvider', () => {
			const NextAppProvider = require('./AppProvider').default
			ReactDOM.render(
				<NextAppProvider />,
				rootElement
			)
		})
	}
}