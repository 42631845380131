import React, { Component } from 'react';
import styles from './iframe.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import FullScreenIcon from 'svgComponents/fullScreenIcon';
import Close from 'svgComponents/close';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import ExitConfirmation from './exitConfirmation';
import SupportIcon from 'svgComponents/supportIcon';
import AppLoader from 'commonComponents/appLoader';
import FullScreen from 'commonComponents/fullScreen';
import environment from 'utils/environment';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { isIOS } from 'react-device-detect';
import FreshChatBtn from '../../freshchatBtn/FreshChatBtn';
import { getActiveBatchDetailsWithId, getActiveBatchId } from '../../../util/batchUtils';
import { checkIfValueIsTrue } from "utils/utilFunctions";

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ItemIframe extends Component {

    constructor(props) {
        super();
        this.state = {
            showLoader: true
        }
    }

    componentWillMount() {
        let supportElement = document.getElementById('freshwidget-button');
        if (supportElement)
            supportElement.style.visibility = 'hidden';
    }

    componentWillUnmount() {
        const { setExitPopup, toggleProductLaunchWindow,
            resetProductLaunchDetails, updateFetchUserItemsListStatusForFeedbackScreen } = this.props;
        let supportElement = document.getElementById('freshwidget-button');
        if (supportElement)
            supportElement.style.visibility = 'visible';
        setExitPopup(false);
        toggleProductLaunchWindow(false);
        resetProductLaunchDetails();
        updateFetchUserItemsListStatusForFeedbackScreen(true);

    }

    onClickOfBackIcon = () => {
        const { toggleProductLaunchWindow } = this.props;
        toggleProductLaunchWindow(false);
    }

    onClickOfFullScreen = () => {
        this.props.setFullScreen(!this.props.isFullScreen);
    }

    onClickOfExit = () => {
        const { setExitPopup } = this.props;
        setExitPopup(true);
    }

    onClickOfClose = () => {
        const { toggleProductLaunchWindow } = this.props;
        toggleProductLaunchWindow(false);
    }

    onClickOfSupport = () => {
        let supportElement = document.getElementById('freshwidget-button').querySelector('a');
        if (supportElement)
            supportElement.click()
    }

    toggleLoader = () => {
        // this.setState({
        //     showLoader: !this.state.showLoader
        // });
    }

    getLoader = () => {
        return <AppLoader userState={this.props.userState} />;
    }

    onClickUpdateStatus = () => {
        document.getElementById('assessment-iframe').src = "https://staging-ad-connector.knolskape.io/return-to-connector?SubjectID=XXXXX&DocumentID=XXXXX";
    }

    render() {
        const { product, isFullScreen,
            getLabel, selectedItem,
            skinGuide,
            toggleProductLaunchWindow, isExitPopupOpen, setExitPopup, isHarrisonAssessment } = this.props;

        const myStyles = getSkin(skinGuide);

        let fullScreenToggleText = getLabel('fullscreen_label');
        if (isFullScreen) {
            fullScreenToggleText = getLabel('leave_fullscreen_label');
        }

        const shouldShowPlatformExitButton = this.props.selectedItem.getIn(['settings', 'showPlatformExitButton']) !== "false";
        
        const isSelectedItemSimulation = selectedItem.get("type") === "SIMULATION";
        const activeBatchId = getActiveBatchId();
        const activeBatchDetails = getActiveBatchDetailsWithId(activeBatchId);
        const isFreshchatEnabledSetting = activeBatchDetails.getIn(["journey", "settings", "isFreshchatEnabled"]);
        const isFreshchatEnabled = isSelectedItemSimulation && isFreshchatEnabledSetting && checkIfValueIsTrue(isFreshchatEnabledSetting) ? true : false;

        return (
            <FullScreen
                fullscreenEnabled={isFullScreen}
                onExitFullScreen={() => this.props.setFullScreen(false)}
            >
                <div styleName="iframe-container">
                    {
                        (isExitPopupOpen)
                            ?
                            <div styleName="overlay">
                                <ExitConfirmation
                                    selectedItem={selectedItem}
                                    toggleProductLaunchWindow={toggleProductLaunchWindow}
                                    setExitPopup={setExitPopup}
                                />
                            </div>
                            :
                            null
                    }
                    <div styleName="iframe-header" >
                        <div styleName="header-left" style={{width: shouldShowPlatformExitButton ? '90%' : '95%'}}>
                            {isHarrisonAssessment && <div styleName="item" onClick={this.onClickUpdateStatus}>
                                <div className={css(myStyles.text)}>Update Status</div>
                            </div>}
                            <div styleName="item" onClick={this.onClickOfFullScreen}>
                                <FullScreenIcon />
                                <div className={css(myStyles.text)}>{fullScreenToggleText}</div>
                            </div>
                        </div>

                        {
                            !isLaunchedFromExternalIntegration()
                            && environment.REACT_APP_ENABLE_FRESHDESK === 'true'
                            &&
                            <div styleName="support-btn-cnt" onClick={this.onClickOfSupport}>
                                <div styleName="support-image-cnt">
                                    <SupportIcon />
                                </div>
                                <div className={css(myStyles.supportBtnText)}>
                                    {getLabel('freshdesk_support_label').toLowerCase()}
                                </div>
                            </div>
                        }


                        {
                            isFreshchatEnabled && <FreshChatBtn activeBatchId={activeBatchId} activeBatchDetails={activeBatchDetails}/>
                        }

                        { shouldShowPlatformExitButton && <div styleName="header-right" onClick={this.onClickOfExit}>
                            <div styleName="icon"><Close /></div>
                            <div className={css(myStyles.text)}>{getLabel('exit_label')}</div>
                        </div> }
                    </div>
                    <div styleName="product">
                        {
                            this.state.showLoader && <div styleName="loader-cnt">
                                {this.getLoader()}
                            </div>
                        }
                        <iframe
                            src={product.get('webLaunchUrl')}
                            styleName={isIOS ? 'resp-container-iphone' : 'resp-container'}
                            onLoad={this.toggleLoader}
                            allowFullScreen
                            allow="camera *; microphone *; autoplay"
                            title="Item"
                            id="assessment-iframe"
                        />
                    </div>
                </div>
            </FullScreen>
        );
    }
}

export default ItemIframe;
