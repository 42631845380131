import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    let textStyle = {
        ...myTypography.button,
        color: skin.get('white'),
        fontWeight: 'normal',
        '@media only screen and (max-width: 480px)': {
            paddingLeft: '5px',   
        }
    };

    return StyleSheet.create({
        text: textStyle,
        textForEmbedded: {
            ...textStyle,
            color: skin.get('black')
        },
        supportBtnText: {
            ...textStyle,
            padding: 'unset',
            textTransform: 'capitalize'
        }
    });
}

export default getSkin;