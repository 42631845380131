import React, { Component } from 'react';
import styles from './assessment.module.sass';
import CSSModules from 'react-css-modules';
import MenuList from 'commonComponents/menuList';
import NextItem from 'commonComponents/nextItem';
import ItemTitle from './itemTitle';
import ItemDetails from './itemDetails';
import ToolFAQ from 'commonComponents/toolFAQ';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import ItemIframe from './iframe';
import applySkin from 'utils/SkinProvider';
import { getUserActiveBatchDetails, getActiveBatchDetails } from 'utils/batchUtils';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import Footer from 'commonComponents/footer';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Dialog from 'commonComponents/dialog/Dialog';
import ItemProgress from './itemProgress/ItemProgress';
import { getFAQsForItem } from 'containers/containerHelpers/assessmentContainerHelper.js';
import { checkIfPresent } from 'utils/utilFunctions';
import enumConstants from 'constants/enums';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import ComponentErrorBoundary from 'components/errorBoundaries/componentErrorBoundary';
import PhaseItemFeedbackContainer from 'containers/PhaseItemFeedbackContainer';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class Assessment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            checkIfPresent(nextProps.activePhaseDetails)
            && checkIfPresent(nextProps.phases)
        ) {
            const currentUserPhase = nextProps.phases.get('userPhasesList').filter((userPhase) => {
                return (userPhase.get('id') === nextProps.activePhaseDetails.id)
            });
            if (
                currentUserPhase.size !== 0
                && currentUserPhase.get(0).get('workflowState') == enumConstants.get('COMPLETED')
            ) {
                this.props.setUserState({
                    makeReportAvailable: true
                });
            }
        }
    }

    componentDidMount() {
        const screenWidth = typeof window === 'object' ? window.innerWidth : null;

        if (screenWidth >= 1280) {
            this.props.setSideBarFlag(true);
        }

        this.props.fetchNextPhaseItemDetailsAndProgress(this.props.selectedItem, +this.props.selectedItem.get('batchId'));
    }

    goToJourneyDashboard = () => {
        const { isSideBarOpen } = this.props;
        this.props.setSideBarFlag(!isSideBarOpen);
        const route = routes.get('BATCH');
        const params = [{ 'BATCH_ID': this.props.userState.get('currentBatchId') }];
        updateRoute({ route, params });
    }

    toggleDialog = (message = null) => {
        this.setState({
            showDialog: !this.state.showDialog,
            dialogMessage: message
        });
    }

    renderDialog = () => {
        if (!this.state.showDialog) {
            return null;
        }

        const popupMessage = this.state.dialogMessage || this.props.getLabel('item_unavailable_label');
        const popupOkButtonText = this.props.getLabel('okay_label');;
        return (
            <Dialog
                key="item-status-dialog"
                dialogMessage={popupMessage}
                okAction={this.toggleDialog}
                okButtonText={popupOkButtonText}
            />
        );
    }

    getAttachmentDetails = (phaseItem, attachmentsList) => {
        if (phaseItem.get('type') === enumConstants.get('ATTACHMENT')) {
            return attachmentsList.find(attachment => attachment.get('uuid') === phaseItem.get('attachmentId'))
        }
        return null;
    }

    renderFeedbackComponent = () => {
        const { selectedItem } = this.props;
        if(selectedItem.get('type') !== enumConstants.get('SIMULATION') && selectedItem.get('type') !== enumConstants.get('HARRISON_ASSESSMENTS') && selectedItem.get('type') !== enumConstants.get('TALENT_PULSE_ASSESSMENT')){
            return <PhaseItemFeedbackContainer batchId={selectedItem.get('batchId')} itemId={selectedItem.get('id')} phaseItem={selectedItem}/>;
        }
    }

    returnMainComponent = () => {
        const { isSideBarOpen,
            toggleProductLaunchWindow,
            setSelectedItemId,
            nextItem,
            selectedUserItem,
            setSideBarFlag,
            selectedItem,
            faqsList,
            activePhaseDetails,
            itemsListInActivePhase,
            isFullScreen,
            setFullScreen,
            setUserState,
            updateItemIdFromRoute,
            setItemWorkflowStatus,
            location,
            loaders,
            setExitPopup,
            isExitPopupOpen,
            resetProductLaunchDetails,
            batches,
            attachmentsList
        } = this.props;

        const itemId = +this.props.match.params.itemId;
        const apiError = this.props.apiErrors.get('fetchPhaseItemsError') || this.props.apiErrors.get('fetchUserPhaseItemsError') || this.props.apiErrors.get('fetchPhaseItemDetailsError');

        const userActiveBatchDetails = getUserActiveBatchDetails(batches);
        const activeBatchDetails = getActiveBatchDetails(batches);
        const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
        const isProductLaunchWindowOpen = items.get('isProductLaunchWindowOpen');
        const userItemsList = items.get('userItemsList');

        let styleSideBar = 'side-bar-collapsed';
        let assessmentStyle = 'assesment-items-collapsed';
        if (isSideBarOpen) {
            styleSideBar = 'side-bar-expanded';
            assessmentStyle = 'assesment-items-expanded';
        }
        let showCompletedBar = false
        if (
            checkIfPresent(activePhaseDetails)
            && checkIfPresent(activePhaseDetails.settings)
            && checkIfPresent(activePhaseDetails.settings.isReportScreenAvailable)
            && activePhaseDetails.settings.isReportScreenAvailable == "false"
            && this.props.userState.get('makeReportAvailable')
        ) {
            showCompletedBar = true;
        }
        const myStyles = getSkin(this.props.skinGuide);

        const isSimulationJourney = activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true';

        const attachmentDetails = this.getAttachmentDetails(selectedItem, attachmentsList)

        if (isProductLaunchWindowOpen) {
            return (
                <ComponentErrorBoundary>
                    <ItemIframe
                        isProductLaunchDetailsAvailable={items.get('isProductLaunchDetailsAvailable')}
                        product={items.get('productLaunchDetails')}
                        isProductLaunchWindowOpen={isProductLaunchWindowOpen}
                        toggleProductLaunchWindow={toggleProductLaunchWindow}
                        resetProductLaunchDetails={resetProductLaunchDetails}
                        isFullScreen={isFullScreen}
                        setFullScreen={setFullScreen}
                        selectedItem={selectedItem}
                        setExitPopup={setExitPopup}
                        isExitPopupOpen={isExitPopupOpen}
                        updateFetchUserItemsListStatusForFeedbackScreen={this.props.updateFetchUserItemsListStatusForFeedbackScreen}
                        isHarrisonAssessment={selectedItem.get('type') === enumConstants.get('HARRISON_ASSESSMENTS')}
                    />
                </ComponentErrorBoundary>
            );
        }
        else {
            return (
                <div styleName="side-bar-product-container">
                    {
                        !(activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true') &&
                        <div styleName={`${styleSideBar} menu-container`}
                            style={{
                                position: this.props.userState.get('makeLeftBarFixed') ? 'fixed' : 'absolute',
                                height: this.props.userState.get('makeLeftBarFixed') ? '100vh' : 'calc(100vh - 67px)'
                            }}
                        >
                            <ComponentErrorBoundary>
                                <MenuList
                                    selectedUserItem={selectedUserItem}
                                    list={itemsListInActivePhase}
                                    userItemsList={userItemsList}
                                    isSideBarOpen={isSideBarOpen}
                                    activePhaseDetails={activePhaseDetails}
                                    setSideBarFlag={setSideBarFlag}
                                    phases={phases}
                                    batchId={selectedItem.get('batchId')}
                                    setUserState={setUserState}
                                    location={location}
                                    updateItemIdFromRoute={updateItemIdFromRoute}
                                    goToJourneyDashboard={this.goToJourneyDashboard}
                                    match={this.props.match}
                                    toggleDialog={this.toggleDialog}
                                    userState={this.props.userState}
                                    apiErrors={this.props.apiErrors}
                                    attachmentsList={attachmentsList}
                                    fetchItemData={this.props.fetchItemData}
                                    currentPhaseData={activePhaseDetails} // this is getting calculated differently hence duplicate
                                    feedbackStatuses={this.props.feedbackStatuses}
                                />
                            </ComponentErrorBoundary>
                        </div>
                    }
                    <div
                        styleName={`${assessmentStyle} content-row`}
                        style={activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true' ? { marginLeft: 'unset' } : {}}
                    >
                        <ComponentErrorBoundary>
                            <APIErrorBoundary
                                key={itemId}
                                apiError={apiError}
                                retryHandler={() => this.props.fetchItemData(+this.props.match.params.batchId, itemId)}
                                style={{
                                    width: '100vw',
                                    height: 'calc(100vh - 150px)',
                                    marginTop: '150px'
                                }}
                            >
                                {
                                    showCompletedBar
                                        ? <div className={css(myStyles.completedTitleContainer)} styleName='completed-title-container'>
                                            <div className={css(myStyles.completedTitleImage)} styleName="completed-title-image" ></div>
                                            <div className={css(myStyles.completedContent)} styleName="completed-content">
                                                {this.props.getLabel('completed_phase_label')}
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    this.props.userState.get('showStickyHeader')
                                        ? <div style={{
                                            width: activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true' ? '100%' : (isSideBarOpen ? 'calc(100% - 350px)' : '100%'),
                                            position: 'fixed',
                                            top: 0,
                                            left: isSideBarOpen ? 'none' : 0,
                                            right: isSideBarOpen ? 0 : 'none',
                                            backgroundColor: 'white',
                                            zIndex: 3,
                                            height: 'auto',
                                            marginLeft: isSideBarOpen ? '350px' : 0,
                                            marginLeft: activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true' ? 'unset' : ''
                                        }}>
                                            <ItemTitle {...this.props}
                                                activePhaseDetails={activePhaseDetails}
                                                selectedUserItem={selectedUserItem}
                                                isSideBarOpen={isSideBarOpen}
                                                setFullScreen={setFullScreen}
                                                setItemWorkflowStatus={setItemWorkflowStatus}
                                                // divRef={this.props.userState.get('stickyRef')}
                                                showStickyHeader={this.props.userState.get('showStickyHeader')}
                                                isSimulationJourney={isSimulationJourney}
                                                activeBatchDetails={activeBatchDetails}
                                                attachmentDetails={attachmentDetails}
                                            />
                                        </div>
                                        : null
                                }
                                <ItemTitle {...this.props}
                                    activePhaseDetails={activePhaseDetails}
                                    selectedUserItem={selectedUserItem}
                                    isSideBarOpen={isSideBarOpen}
                                    setFullScreen={setFullScreen}
                                    setItemWorkflowStatus={setItemWorkflowStatus}
                                    divRef={this.props.userState.get('stickyRef')}
                                    showStickyHeader={this.props.userState.get('showStickyHeader')}
                                    isSimulationJourney={isSimulationJourney}
                                    activeBatchDetails={activeBatchDetails}
                                    attachmentDetails={attachmentDetails}
                                />

                                <div styleName="all-content-row">

                                    {
                                        (loaders.get('isFetchingUserItemsListForFeedbackScreen'))
                                            ?
                                            <ItemProgress isSideBarOpen={isSideBarOpen} />
                                            :
                                            [
                                                <ItemDetails
                                                    isSideBarOpen={isSideBarOpen}
                                                    selectedItem={selectedItem}
                                                    selectedUserItem={selectedUserItem}
                                                    activePhaseDetails={activePhaseDetails}
                                                    setItemWorkflowStatus={setItemWorkflowStatus}
                                                    attachmentDetails={attachmentDetails}
                                                    fetchNextItemDetailsAndProgress={this.fetchNextItemDetailsAndProgress}
                                                    saveItemProgress={this.props.saveItemProgress}
                                                    selectedItemProgress={this.props.selectedItemProgress}
                                                    productLaunchDetails={items.get('productLaunchDetails')}
                                                    toggleProductLaunchWindow={toggleProductLaunchWindow}
                                                    fetchProductLaunchData={this.props.fetchProductLaunchData}
                                                    resetProductLaunchDetails={resetProductLaunchDetails}
                                                    isProductLaunchDetailsAvailable={items.get('isProductLaunchDetailsAvailable')}
                                                    refreshPhasesAndPhaseItems={this.props.refreshPhasesAndPhaseItems}
                                                />,
                                                activePhaseDetails && activePhaseDetails.type !== "Learning" &&
                                                <ToolFAQ
                                                    faqsList={getFAQsForItem(faqsList, itemId, this.props.items)}
                                                    isSideBarOpen={isSideBarOpen}
                                                    loaders={loaders}
                                                />,
                                                this.renderFeedbackComponent(),
                                                <NextItem {...this.props}
                                                    nextItem={nextItem}
                                                    activePhaseDetails={activePhaseDetails}
                                                    setSelectedItemId={setSelectedItemId}
                                                    setUserState={setUserState}
                                                    toggleDialog={this.toggleDialog}
                                                    userActiveBatchDetails={userActiveBatchDetails}
                                                />,
                                            ]
                                    }
                                    <Footer />

                                </div>
                            </APIErrorBoundary>
                        </ComponentErrorBoundary>
                    </div>
                </div>
            );
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="assesment-container" className={css(myStyles.assessmentBorder)}>
                {this.returnMainComponent()}
                {this.renderDialog()}
            </div>
        );
    }
}

export default Assessment;
