import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './assignment.module.sass';
import applyLabel from 'utils/LabelProvider';
import DragAndDrop from './DragAndDrop';
import isEmpty from 'lodash/isEmpty';
import {escapeScriptTags} from 'utils/fileUtil';

@applySkin
@applyLabel
@CSSModules(styles, {allowMultiple: true})
class FileUpload extends Component {

    constructor(props){
        super(props);
        this.state = {
            invalidFileError: false
        }
    }

    onFileSelect = (event) => {
        const files = event.target.files;
        if(this.doSelectedFilesHaveValidExtensions(files)){
            this.uploadSelectedFiles(files);
        }
    }

    doSelectedFilesHaveValidExtensions = (files) => {
        const assignment = this.props.moduleItem.get('contentDetails');
        const listOfAllowedExtensions = assignment.get('allowed_extensions') ? assignment.get('allowed_extensions').toJS() : [];
        let extension = null;

        if(isEmpty(listOfAllowedExtensions)){
            this.setInvalidFileError(false);
            return true;
        }

        for (var i = 0; i < files.length; i++) {
            extension = files[i].name.split('.').pop().toLowerCase();
            console.log(extension, listOfAllowedExtensions);
            if(!listOfAllowedExtensions.includes(extension)){
                this.setInvalidFileError(true);
                return false;
            }
        }

        this.setInvalidFileError(false);
        return true;
    }

    setInvalidFileError = (status) => {
        this.setState({invalidFileError: status});
    }

    onFileDrop = (files) => {
        if(this.doSelectedFilesHaveValidExtensions(files)){
            this.uploadSelectedFiles(files);
        }
    }

    uploadSelectedFiles = (files) => {
        for (var i = 0; i < files.length; i++) {
            const file = files[i];

            if (!file.type.startsWith('image/svg+xml')) {
                this.uploadOneFile(file);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;               
                const sanitizedContent = escapeScriptTags(fileContent);
                const sanitizedFile = new File([sanitizedContent], file.name, { type: file.type });
                this.uploadOneFile(sanitizedFile);
            };
            reader.readAsText(files[i]);
        }
    }

    uploadOneFile = (file) => {
        const {match, uploadFile} = this.props;
        uploadFile(match.params, file);
    }

    renderUploadedFiles = () => {
        const styles = getSkin(this.props.skinGuide);
        const uploadedFiles = this.props.moduleItem.get('uploadedFiles');
        return uploadedFiles.map((file, index) => {
            return <div styleName="uploaded-file-cnt" key={`ufile_${index}`}>
                <span className={css(styles.uploadedFileName)}>{file.get('name')}</span>
                <span onClick={() => this.props.removeFile(file.get('id'))} styleName="remove"> x</span>
            </div>;
        });
    }

    render() {
        const {getLabelWithDefaults, moduleItem} = this.props;
        const invalidFileError = this.state.invalidFileError;
        const assignment = moduleItem.get('contentDetails');
        const listOfAllowedExtensions = assignment.get('allowed_extensions') ? assignment.get('allowed_extensions').toJS() : [];

        const styles = getSkin(this.props.skinGuide);

        return (
            <div className={css(styles.uploadContainer)} styleName="upload-container">
                
                <DragAndDrop handleDrop={this.onFileDrop}>
                    <div styleName="dropzone">
                        <img styleName="upload-icon" src="https://webassets.knolskape.com/misc/amul_garg/2019/03/13/24/file-upload-material3x.png"/>
                    </div>
                </DragAndDrop>
                
                <div styleName="upload-instruction" className={css(styles.uploadInstruction)}> 
                    {getLabelWithDefaults('drag_and_drop_label', 'Drag and drop or')}
                </div>

                <div styleName="upload-btn-wrapper">
                    <button styleName="upload-btn" className={css(styles.uploadButton)}>{getLabelWithDefaults('assignment_browse_file_label', 'Browse')}</button>
                    
                    <input type="file" onChange={this.onFileSelect} ref="file_input" name="file" id="file" multiple/>

                </div>

                <div styleName="uploaded-files" className={css(styles.uploadInstruction)}>
                    {moduleItem.get('fileUploadInProgress')? getLabelWithDefaults('assignment_file_uploading_label', 'Uploading...') : null}
                </div>

                <div styleName="error-cnt">{invalidFileError ? `Allowed File Extensions: ${listOfAllowedExtensions.join(',')}` : null}</div>

                {this.renderUploadedFiles()}
            </div>
        )
    }
}

export default FileUpload;