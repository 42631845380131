import React, {useEffect} from 'react'

import DailyIframe from '@daily-co/daily-js';

export default function DailyCoPOC() {

	useEffect(() => {
		let callFrame = DailyIframe.wrap(document.getElementById('daily-iframe'));
		callFrame.join({ 
			url: 'https://knold.daily.co/test-1',
			token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1ZCI6MTIzLCJ1IjoidGVzdC11c2VyLTEiLCJvIjpmYWxzZSwiZCI6ImQ4ZWMyYzg2LTczN2EtNDg4NS04MDE1LWVhYjc2N2RkZDI0YSIsImlhdCI6MTY0NTAyODc3N30.2w-TTbL_B5Wf_90AKUM-AAw129_sE9b-NeY7jCKWPYo"
		});
	}, [])


  return (
    <div style={{'margin': 'auto', width: '800px', height: '800px', marginTop: '50px'}}>
			<iframe id="daily-iframe" width={800} height={800} title="video-frame" style={{border:0}}
				allow="microphone; camera; autoplay; display-capture"/>
			/>
		</div>
  )
}
