/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: MeasuresContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Measures from 'components/measures';

import { fetchMeasureData } from 'actions/measures/apiActions';
import { setActivePhaseId } from 'actions/phases/actionCreators';
import { setPhaseWorkflowStatus } from 'actions/phases/apiActions';
import { getActivePhase } from './containerHelpers/assessmentContainerHelper';
import setUserState from 'actions/userState/actionCreators';
import { setInvalid } from 'actions/invalid/actionCreators';
import {checkIfPresent} from 'utils/utilFunctions';

import {
	setFeedbackCompletionStatus,
	setResponseToQuestion, resetUserData
} from 'actions/itemFeedback/actionCreators';

import {
	fetchItemFeedback,
	fetchUserItemFeedback,
	submitAnswer,
	submitFeedback
} from 'actions/itemFeedback/apiActions';
import { filterObjectFromArray } from 'utils/objectUtils';

import enumConstants from 'constants/enums';

import {goToDashboard} from 'utils/webAppRoutes/updateRoute';
import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';

class MeasuresContainer extends React.Component {

	componentDidMount() {
		const { batchId, phaseId } = this.props.match.params;

		startTracking(true, {
			batchId: batchId,
			phaseId: phaseId
		}, 'MeasuresScreen');

		this.props.setUserState({
			currentPhaseId: parseInt(phaseId)
		});
		this.props.fetchMeasureData(batchId, phaseId);
	}

	componentWillReceiveProps(nextProps) {
		const { batchId, phaseId } = nextProps.match.params;
		if (
			this.props.match.params.batchId != batchId
			|| this.props.match.params.phaseId != phaseId
		) {
			/* MIXPANEL  start*/
			endTracking();
			startTracking(true, {
				batchId: batchId,
				phaseId: phaseId
			}, 'MeasuresScreen');
			/* MIXPANEL  end*/

			this.props.fetchMeasureData(batchId, phaseId);
		}

		this.props.setUserState({
			currentPhaseId: parseInt(phaseId)
		});

		this.checkIfAvailable(nextProps.activePhaseDetails);
	}

	isPhaseCompleted = (phaseId) => {
		const userPhase = filterObjectFromArray(this.props.phases.get('userPhasesList'), 'id', phaseId);
		return userPhase.get('workflowState') == enumConstants.get('COMPLETED');
	}

	checkIfAvailable = (activePhaseDetails) => {
		if (!activePhaseDetails) return;
		let isAvailable = true;
		let isPhaseCompleted = this.isPhaseCompleted(activePhaseDetails.id);
		if (activePhaseDetails.isLocked || (activePhaseDetails.isExpired && !isPhaseCompleted)) {
			isAvailable = false;
		}

		if (
			checkIfPresent(activePhaseDetails)
			&& checkIfPresent(activePhaseDetails.settings)
			&& checkIfPresent(activePhaseDetails.settings.isReportScreenAvailable)
			&& activePhaseDetails.settings.isReportScreenAvailable == "false"
		) {
			isAvailable = false;
		}

		if (!isAvailable) {
			goToDashboard();
		}

	}

	componentWillUnmount() {
		endTracking();
	}

	render() {
		return <Measures {...this.props} />;
	}
}


const submitAnswerFromContainer = (payload, questionId) => {
	return (dispatch, getState) => {
		const itemId = getState().get('userState').get('currentPhaseId');
		const entityType = enumConstants.get('ENTITY_TYPE_PHASE');
		const scenario = enumConstants.get('SCENARIO_PHASE');
		dispatch(submitAnswer(payload, questionId, itemId, entityType, scenario));
	}
}


const submitFeedbackFromContainer = (payload) => {
	return (dispatch, getState) => {
		const itemId = getState().get('userState').get('currentPhaseId');
		const entityType = enumConstants.get('ENTITY_TYPE_PHASE');
		const scenario = enumConstants.get('SCENARIO_PHASE');
		dispatch(submitFeedback(payload, itemId, entityType, scenario));
	}
}


const mapStateToProps = (state) => {
	return ({
		measures: state.get('measures'),
		phases: state.get('phases'),
		activePhaseDetails: getActivePhase(state),
		itemFeedback: state.get('itemFeedback'),
		items: state.get('items'),
		errors: state.get('errors'),
		apiErrors: state.get('apiErrors')
	});
}

const mapDispatchToProps = (dispatch) => ({
	fetchMeasureData: (batchId, phaseId, entityType = "PHASE", scenario = "PHASE") => {
		dispatch(fetchMeasureData(batchId, phaseId, entityType, scenario));
	},
	updateActivePhase: (phaseId) => {
		dispatch(setActivePhaseId(phaseId));
	},
	setPhaseWorkflowStatus: (batchId, phaseId, workflowStatus) => {
		dispatch(setPhaseWorkflowStatus(batchId, phaseId, workflowStatus))
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},

	setFeedbackCompletionStatus: (flag) => {
		dispatch(setFeedbackCompletionStatus(flag))
	},
	setResponseToQuestion: (payload, questionId) => {
		dispatch(setResponseToQuestion(payload, questionId))
	},
	submitAnswer: (payload, questionId) => {
		dispatch(submitAnswerFromContainer(payload, questionId))
	},
	submitFeedback: (payload) => {
		dispatch(submitFeedbackFromContainer(payload))
	},
	resetUserData: (flag) => {
		dispatch(resetUserData(flag))
	},
	setInvalid: (flag) => {
		dispatch(setInvalid(flag));
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasuresContainer));