/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: app.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * App reducer is a high level reducer which combines all the sub reducers using
 * default redux "combineReducers" functionality
 *
 **/

import { combineReducers } from 'redux-immutable';
import user from './user/user';
import skin from './skin/skin';
import sim from './sim/sim';
import theme from './theme/theme';
import netpack from './netpack/netpack';
import phases from './phases/phases';
import moduleItem from './moduleItems/moduleItem';
import labels from './labels/labels';
import batches from './batches/batches';
import items from './items/items';
import ui from './product/ui';
import tools from './tools/tools';
import measures from './measures/measures';
import uiState from './uiState/uiState';
import course from './courses/course';

import journeyCoursesInfo from './courses/journeyCoursesInfo';
import nextActivityItem from './moduleItems/nextActivityItem';
import loaders from './loaders/loaders';
import courseList from './courseList/courseList';
import quiz from './quiz/quiz';
import userState from './userState/userState';
import allGraphsData from './analytics/analytics';
import isPlatformDataAvailableForDevelopmentPhase from './developmentPhase/isPlatformDataAvailableForDevelopmentPhase';
// import graphsData from './analytics/analyticsGen';

import toasts from './toasts/toasts';
import errors from './errors/errors';
import itemFeedback from './itemFeedback/itemFeedback';
import moduleItemsCache from './moduleItems/moduleItemsCache';

import courseForNextActivity from './courses/courseForNextActivity';
import apiErrors from './errors/apiErrors';

import phaseCourses from './courses/phaseCourses';
import coursesModulesList from './courses/coursesModulesList';
import modulesItemsList from './courses/modulesItemsList';
import coursesActiveSections from './courses/coursesActiveSections';
import reports from './reports/reports';
import userOrganization from './home/userOrganization';
import organizationLevelUsersStats from './home/organizationLevelUserStats';
import journeyWiseTimeSpent from './home/journeyWiseTimeSpent';

import msTeamsLeaderboard from './msTeams/leaderboard';
import mixpanelTracking from './mixpanelTracking/mixpanelTracking';

import journeyFormats from './enterprise/journeyFormats';
import journeyCategories from './enterprise/journeyCategories';
import journeyTags from './enterprise/journeyTags';
import availableJourneys from './enterprise/availableJourneys';

import selectedJourneyInstance from './journeys/selectedJourneyInstance';
import userProfile from './userProfile/userProfile';
import feedbackStatuses from './feedbackStatuses/feedbackStatuses';
import batchFeedbackStatus from './feedbackStatuses/batchFeedbackStatus';
import batchesFeedbackStatusFlags from './feedbackStatuses/batchesFeedbackStatusFlags';
import userWhitelistingRequirements from './userWhitelistingRequirements/userWhitelistingRequirements';
import attachments from './attachments/attachments';
import page from './page/page';
import surveyQuiz from './surveyQuiz/surveyQuiz';
import corpDeckJourneys from './enterprise/corpDeckJourneys';


const app = combineReducers({
    skin,
    sim,
    user,
    theme,
    netpack,
    phases,
    moduleItem,
    labels,
    batches,
    items,
    ui,
    tools,
    journeyCoursesInfo,
    measures,
    uiState,
    course,
    loaders,
    courseList,
    quiz,
    userState,
    allGraphsData,
    toasts,
    nextActivityItem,
    errors,
    itemFeedback,
    moduleItemsCache,
    isPlatformDataAvailableForDevelopmentPhase,
    courseForNextActivity,
    apiErrors,
    phaseCourses,
    coursesModulesList,
    modulesItemsList,
    coursesActiveSections,
    reports,
    msTeamsLeaderboard,
    mixpanelTracking,
    userOrganization,
    organizationLevelUsersStats,
    journeyWiseTimeSpent,
    journeyFormats,
    journeyCategories,
    journeyTags,
    availableJourneys,
    selectedJourneyInstance,
    userProfile,
    feedbackStatuses,
    batchFeedbackStatus,
    batchesFeedbackStatusFlags,
    userWhitelistingRequirements,
    attachments,
    page,
    surveyQuiz,
    corpDeckJourneys
});

export default app;
